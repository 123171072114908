import React from "react";
import logo from "../../../assets/icons/logo.svg";

function Logo({isSidebar}) {
  return (
    <div className="flex flex-col justify-center items-center mb-1" data-aos="fade-up">
      <img src={logo} alt="logo" className="h-[9vh] duration-300" />
      <div
        className={`${
          isSidebar ? 'text-white' : 'text-neutral-700 dark:text-white'
        }  text-2xl font-normal font-dangrek`}
      >
        SPORTS 909
      </div>
    </div>
  );
}

export default Logo;
