import { createSlice } from "@reduxjs/toolkit";
import { decrypt, encrypt } from "../../../helpers/encrypt";

const initialState = {
  token: localStorage.getItem("token"),
  userDetails: localStorage.getItem("userDetails")
    ? JSON.parse(localStorage.getItem("userDetails"))
    : localStorage.getItem("userDetails"),
  rememberMe: localStorage.getItem("rememberMe") ? true : false,
  credential: localStorage.getItem("credential"),
};

export const loginAction = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginData: (state, action) => {
      state.token = action.payload.token;
      state.userDetails = action.payload.userDetails;
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem(
        "userDetails",
        JSON.stringify(action.payload.userDetails)
      );
      state.rememberMe = action.payload.rememberMe ? true : false;
      if (action.payload.rememberMe) {
        const credential = encrypt({
          username: action.payload.username,
          password: action.payload.password,
        });
        state.credential = credential;
        localStorage.setItem("rememberMe", "true");
        localStorage.setItem("credential", credential);
      } else {
        state.credential = null;
        localStorage.removeItem("rememberMe");
        localStorage.removeItem("credential");
      }
    },
    setRememberMe: (state) => {
      state.rememberMe = !state.rememberMe;
    },
  },
});

export const { setLoginData, setRememberMe } = loginAction.actions;
export const selectLoginData = (state) => state.login;

export default loginAction.reducer;
