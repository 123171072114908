import React, { useRef } from "react";
import forwardArrow from '../../../assets/icons/forward.svg';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { playSong } from '../../common/actions/common.actions';
import { IoIosArrowBack } from "react-icons/io";

const Gift = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleBackClick = () => {
    handleSongClick();
    navigate('/dashboard');
  };

  return (
    <div className="w-full flex flex-col h-screen bg-cover md:bg-fit bg-no-repeat bg-center font-dangrek">
      <div className="flex justify-start items-center py-2 dark:shadow-sm">
        <button
          className="top-3 left-5 w-10 h-10
                    rounded-full drop-shadow-lg flex justify-center items-center text-gray-900 dark:text-white text-4xl"
          onClick={handleBackClick}
        >
          {/* <img src={forwardArrow} alt="Back Arrow" /> */}
          <IoIosArrowBack size={25} className="text-content" />
        </button>
        <div>
          <h3 className="text-neutral-700">SuperSundayGift</h3>
        </div>
      </div>
      <div className="flex-1 flex justify-center items-center text-neutral-700">
        <span>Coming Soon...</span>
      </div>
    </div>
  );
}

export default Gift;
