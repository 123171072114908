export const apiList = {
  // baseUrl: 'http://139.162.3.8/api/',
  baseUrl: 'https://ag.sport909.com/api/',
//  baseUrl: 'https://ag.bluebetta.me/api/',

  appVersion: "1.0.0.1",
  loginApi: 'LogIn/authenticate',
  userListApi: 'UserBalance/balance/',
  todayEvent: 'UserBetting/geteventdata',
  confirmBetting: 'UserBetting',
  getUserInfoAndCommission: 'TblUsers/GetUserDataWithBalance/',
  editUserInfoAndCommission: 'TblUsers/',
  getAllRoleWithRoleId: 'TblRoles/GetAllRollWithRoleId/',
  getTransactionsDetails: 'UserBalance/GetTransactionDetailsForUserWithDate',
  getTransactionsForUser: 'UserBalance/GetTransactionForUser',

  getTransactionsForDashboard: 'UserBalance/GetTransactionForDashboard',
  //add new
  userBalanceAdd: 'UserBalance/add/', //body json {Amount:0000}
  userBalanceRemove: 'UserBalance/remove/', //body json {Amount:0000}
  userCreditAdd: 'UserBalance/credit/', //body json {Amount:0000}
  userLock: 'UserSetting/lock', //body json {userId:0,isLock:true}
  resetPassword: 'UserSetting/reset', //body json {userId:0,OldPassword:0,NewPassword:0}
  changePassword: 'UserSetting/change', //body json {userId:0,CurrentPassword:0,NewPassword:0,ConfirmPassword:0}
  getRuleAndRegulation: 'Rule',
  getDisplayText: 'Regulation',
  getServerTime: 'UserBetting/getTime',
  predictprofit: 'UserBetting/predictprofit',

  bettingHistory: 'UserBetting/InplayBetting',
  bettingOldHistory: 'BettingHistory/searchBetHistory',
  bettingHistoryDetails: 'UserBetting/bettingHistoryDetails',
  betAmountLimitGet: 'UserBalance/getMinMax',
  betAmountLimitForAdmin: 'UserBalance/minmax/', //need gamblingTypeId route param//body json {MinBetAmount:0,MaxBetAmount:0}
  getTransactionsForCashBook: 'UserBalance/GetTransactionBalanceForMobileUser', //param//body json{UserId:0,StartDate:date,EngDate:date}
  checkBettingOdd: 'UserBetting/check', //body obj {{'eventId':1}},
  isLockOrNot: 'LogIn/IsLockOrNot',
  goldScore: 'GoalsResult/SearchGoalsResult',
  hotNews: 'HotNews'
};
