import React from "react";
// import blackShoe from "../../../assets/icons/blackshoe.svg";
// import shoeIcon from "../../../assets/icons/shoe.svg";
// import SoccerBall from "../../../assets/icons/soccerr.svg";
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import bettingService from "../../../api/services/bettingService";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { BiFootball } from 'react-icons/bi';
import { GiRunningShoe } from 'react-icons/gi';

const SelectedMatchesCard = ({ match }) => {
    const { t } = useTranslation();
    const language = localStorage.getItem("language");
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setData(null);
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await bettingService.bettingHistoryDetails({
                    gamblingId: Number(match.gamblingId),
                    status: "Inplay",
                });
                const res = await response.json();
                if (res && res.length > 0) {
                    setData({ ...match, ...res[0] });
                }
            } catch (err) {
                if (err?.response?.status === 401) {
                    // Redirect to the login page
                    navigate("/login");
                } else {
                    console.error("API request error:", err);
                }
            } finally {
                setIsLoading(false);
            }
        };
        match.gamblingTypeId === 1 && fetchData();
    }, [match, navigate]);

    return (
        <div className='flex flex-col pt-4 pb-2 mx-2 my-3 rounded-md h-max dark:border dark:border-border_rule_btn/[0.1] dark:text-white shadow-custom font-myanmarSansPro'>
            <div className='flex justify-between items-center pl-2 dark:pl-0 pr-3'>
                <div className='flex'>
                    {/* <span className="dark:hidden pr-3">
                        <img src={match?.gamblingTypeId === 1 ? SoccerBall : blackShoe} alt="Black Shoe"></img>
                    </span>
                    <span className="hidden dark:block px-2">
                        <img src={match?.gamblingTypeId === 1 ? SoccerBall : shoeIcon} alt="Soccer"></img>
                    </span> */}
                    {
                        match?.gamblingTypeId === 1 ?
                            <span className="dark:pl-3 dark:pr-4">
                                <BiFootball size={24} className="mr-5 dark:m-0" />
                            </span>
                            :
                            <span className="dark:px-3">
                                <GiRunningShoe size={24} className="mr-4 dark:m-0" />
                            </span>
                    }
                    <h2 className='text-md font-bold'>{match?.gamblingTypeId === 1 ? t("body") : t("maungg")}</h2>
                </div>
                <p className="font-dangrek text-sm dark:text-league_text text-background">
                    {
                        moment(new Date(match?.createdDate)).format('DD.MM.YYYY h:mm A')
                    }
                </p>
            </div>
            <div className="px-5">
                <div className='grid grid-cols-2 gap-2 pl-8 py-2 items-center mt-2'>
                    <h4 className="text-bg_header dark:text-white font-dangrek">BetID</h4>
                    <p className="text-bg-header text-xs cols-span-2 ">{match?.postingNo}</p>
                </div>
                <div className='grid grid-cols-2 gap-4 pl-8 py-1 pt-2'>
                    <h4 className={`text-bg_header dark:text-white ${language === 'en' ? '' : 'font-bold'}`}>{t("betAmount")}</h4>
                    <p className="font-dangrek">{parseInt(match?.amount).toLocaleString()}</p>
                </div>
                {
                    match?.gamblingTypeId === 2 ?
                        <div className='grid grid-cols-2 gap-4 pl-8 py-2'>
                            <h4 className={`text-bg_header dark:text-white ${language === 'en' ? '' : 'font-bold'}`}>{t("teamCount")}</h4>
                            <p className="font-dangrek">{match?.teamCount}</p>
                        </div>
                        : null
                }
                <div className='flex justify-end text-k_selectionBgColor font-dangrek'>
                    <p>{match?.status === 'Inplay' ? 'Ongoing' : ''}</p>
                </div>
                {isLoading && <div className="border-t-4 border-l-2 border-primary ml-7 rounded-full animate-spin w-5 h-5" />}
                {
                    data?.gamblingTypeId === 1 ?
                        <div className="bg-slate-100 py-4 px-8 mt-2 -mx-5 dark:bg-gray-800">
                            <div className="flex flex-col justify-start">
                                <div className="flex">
                                    <div className={`flex-2 pr-2 ${language === 'en' ? '' : 'font-bold'}`}>
                                        <p className={data?.isHomeBodyOdd ? "text-selectMatch" : ""}>
                                            {data.isHome ? language === 'en' ? data?.bettedTeam : data?.bettedTeamMyan : ""}
                                            {!data.isHome ? language === 'en' ? data?.oppositeTeamName : data?.oppositeTeamNameMyan : ""}
                                        </p>
                                        <p className={!data?.isHomeBodyOdd ? "text-selectMatch mt-2" : "mt-2"}>
                                            {!data.isHome ? language === 'en' ? data?.bettedTeam : data?.bettedTeamMyan : ""}
                                            {data.isHome ? language === 'en' ? data?.oppositeTeamName : data?.oppositeTeamNameMyan : ""}
                                        </p>
                                    </div>
                                    <div className="flex-grow text-right font-dangrek">
                                        {data?.isHomeBodyOdd ? <p className="text-selectMatch">« {(data?.home || data?.away) ? data?.bodyOdd : data?.goalOdd}</p> : <br></br>}
                                        {!data?.isHomeBodyOdd && <p className="text-selectMatch">« {(data?.home || data?.away) ? data?.bodyOdd : data?.goalOdd}</p>}
                                    </div>
                                </div>
                                <div className="w-full text-center flex flex-row justify-center items-center space-x-2 font-dangrek">
                                    <p className=" text-primary dark:text-white text-sm">{data?.homeResult}</p>
                                    <BiFootball />
                                    <p className=" text-primary dark:text-white text-sm">{data?.awayResult}</p>
                                </div>
                            </div>

                            <div className='grid grid-cols-2 gap-10 pt-2 text-k_selectionBgColor'>
                                <h4 className={`text-bg_header dark:text-white ${language === 'en' ? '' : 'font-bold'}`}>{t('choice')}</h4>
                                <p className={`${language === 'en' ? '' : 'font-bold'}`}>
                                    
                                    {data.home || data.away ? language === 'en' ? data?.bettedTeam : data?.bettedTeamMyan : ''}
                                    {data.overs ? t("goalOver") : ''}
                                    {data.under ? t("goalUnder") : ''}
                                </p>
                            </div>
                        </div>
                        : null
                }
                
            </div>
        </div>
    );
}

export default SelectedMatchesCard;
