import React from 'react';
import { useDispatch } from 'react-redux';
import { playSong } from '../../common/actions/common.actions';
import { useTranslation } from 'react-i18next';
import Trash from '../../../assets/icons/Trash.png';
import DarkTrash from '../../../assets/icons/Trash_black.png';
import { RiDeleteBin6Line } from "react-icons/ri";

const Card = ({ number, team1, team2, odd, choosen, handleRemoveCard, match, normalColor = true }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const homeTeamName = i18n.language === 'en' ? team1 : match.homeTeamMyan;
  const awayTeamName = i18n.language === 'en' ? team2 : match.awayTeamMyan;

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const removeCard = () => {
    handleSongClick();
    handleRemoveCard(match.rapidEventId);
  }
  return (
    <div className="flex relative flex-col px-2 py-1 rounded-[7px] h-max dark:text-white">
      <div className={`grid grid-cols-12 gap-1 border-2 border-black/[0.2] 
      dark:border-border_confirmBet_dark/[0.2] ${normalColor ? 'dark:bg-white/[0.05]' : 'dark:bg-bg_bet/[0.55] animate-pulse bg-bg_header_dark'}  rounded-[7px] p-2`}>
        <div className="flex col-span-1 items-center text-sm font-dangrek">
          <h4 className="text-football dark:text-white">{number}</h4>
        </div>
        <div className="col-span-5 text-sm">
          <h4 className={`text-football ${match.overId === match.homeId ? "text-selectMatch dark:text-selectMatch" : 'dark:text-white'}`}>{homeTeamName}</h4>
          <h4 className={`text-football ${match.overId === match.awayId ? "text-selectMatch dark:text-selectMatch" : 'dark:text-white'}`}>{awayTeamName}</h4>
        </div>
        <div className={`flex col-span-2 p-1 ${match.overId === match.homeId ? "items-start" : "items-end"}`}>
          <h4 className="text-selectMatch text-sm font-dangrek">{odd}</h4>
        </div>
        <div className="flex col-span-3 items-center text-sm">
          <h4 className="text-football dark:text-white">{choosen === 'home' ? homeTeamName : choosen === 'away' ? awayTeamName : choosen}</h4>
        </div>
        <div className="absolute right-4 top-7" onClick={removeCard}>
          {/* <img src={Trash} alt="" onClick={removeCard} className='dark:hidden' />
          <img src={DarkTrash} alt="" onClick={removeCard} className='hidden dark:block' /> */}
          <RiDeleteBin6Line size={16} className="text-gray-700 dark:text-white" />
        </div>
      </div>
    </div>
  );
};

export default Card;
