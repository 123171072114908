import * as signalR from '@microsoft/signalr';
import { apiList } from "../api/apiList";

// local
// const chatHubUrl = 'https://localhost:5201/RTEvents';

//development
//const chatHubUrl = `${apiList.baseUrl.replace('api/','')}RTEvents`;
const chatHubUrl = `${apiList.baseUrl}messageHub`;

const connection = new signalR.HubConnectionBuilder()
  .withUrl(chatHubUrl) // Adjust the hub URL based on your configuration
  .build();

connection.start().then(function () {
  console.log("Connected to SignalR Hub");
}).catch(function (err) {
    console.log('err occour in signalR', err);
  return console.error(err.toString());
});

export default connection;
