const config = {
  // goal_status: {
  //   0: "NOT STARTED",
  //   1: "INPLAY",
  //   2: "TO BE FIXED",
  //   3: "ENDED",
  //   4: "POSTPONED",
  //   5: "CANCELLED",
  //   6: "WALKOVER",
  //   7: "INTERRUPED",
  //   8: "ABANDONED",
  //   9: "RETIRED",
  // },
  goal_status: {
    0: "",
    1: "",
    2: "",
    3: "F T",
    4: "P P",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
  },
};

export default config;
