import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import LoginService from "../../../api/services/login_Service";
import { apiList } from "../../../api/apiList";
import { useNavigate } from "react-router";
import TextInputFloat from "../../common/components/textInput/TextInputFloat";
import Logo from "../../common/components/Logo";
import UserIcon from "../../../assets/icons/user.svg";
import User from "../../../assets/icons/male.svg";
import { decrypt } from "../../../helpers/encrypt";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { setLoginData, selectLoginData, setRememberMe } from './login.actions';
import Modal from "../../common/components/modal";
import DarkThemeToggleComponent from "../../../darkThemeToggle";

const Login = ({ setIsLoading, showToastMessage }) => {
  const dispatch = useDispatch();
  const { rememberMe, credential } = useSelector(selectLoginData);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  // const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (username !== "" && password !== "") {
      try {
        setIsLoading(true);
        const data = await LoginService.login({
          Username: username,
          Password: password,
          ismobile: true,
          appVersion: apiList.appVersion,
        }).json();
        setIsLoading(false);
        if (data.message === "Your account is locked!") {
          openModal('Sport 909', 'Your account is locked !');
          navigate("/login");
        } else {
          dispatch(setLoginData({ ...data, rememberMe, username, password }));
          navigate("/rules?loggedIn=true");
        }
        // dispatch(setLoginData({ ...data, rememberMe, username, password }));
        // navigate("/rules?loggedIn=true");
        // localStorage.setItem("token", data.token);
        // localStorage.setItem("userDetails", JSON.stringify(data.userDetails));
        // if (rememberMe) {
        //   const credential = encrypt({ username, password });
        //   // console.log("credential", credential);
        //   localStorage.setItem("rememberMe", "true");
        //   localStorage.setItem("credential", credential);
        // } else {
        //   localStorage.removeItem("rememberMe");
        //   localStorage.removeItem("credential");
        // }

      } catch (error) {
        // Show an error toast on login failure
        setIsLoading(false);
        // setIsModalOpen(true);
        openModal('Sport 909', `Login Fail !`);
      }
    }
  };

  const openModal = (title, content) => {
    setModalTitle(title);
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleRememberMeChange = (e) => {
    dispatch(setRememberMe());
  }

  useEffect(() => {
    // const isRememberMe = localStorage.getItem("rememberMe");
    // console.log(isRememberMe);
    // console.log(isRememberMe == "true");
    // const credential = localStorage.getItem("credential");
    if (rememberMe && credential) {
      const decrypted = decrypt(credential);
      // console.log(decrypted);
      setUsername(decrypted?.username || '');
      setPassword(decrypted?.password || '');
    }
    //eslint-disable-next-line
    // setRememberMe(isRememberMe == "true");
  }, [credential, rememberMe]);


  return (
    <div className="flex flex-col items-center justify-center w-full  h-screen bg-white dark:bg-background bg-opacity-90">
      {/* <DarkThemeToggle className="z-50 absolute top-2 right-2" /> */}
      <div className="z-40 absolute cursor-pointer top-3 right-3 mt-2 mr-3 p-1">
        <DarkThemeToggleComponent />
      </div>
      <Logo />
      <div className=" z-50 flex flex-col mt-10 mx-4 sm:mx-0 h-[60vh] bg-white/[0.2] dark:backdrop-blur-sm backdrop-blur-sm dark:bg-primary/[0.2] dark:bg-opacity-30 bg-opacity-30 rounded-md shadow border border-white border-opacity-20">
        <form
          onSubmit={handleLogin}
          className="flex flex-col justify-center items-center h-full rounded-b-md shadow-md px-8 md:px-16 space-y-4"
        >
          <div className="flex items-center justify-center mb-10">
            <span className="text-primary dark:hidden">
              <img src={UserIcon} alt="User Icon"></img>
            </span>
            <span className="text-primary hidden dark:block">
              <img src={User} alt="User Icon"></img>
            </span>
          </div>

          <TextInputFloat
            id={"username"}
            placeholder={"Username"}
            value={username}
            type="text"
            setValue={setUsername}
          />
          <TextInputFloat
            id={"password"}
            placeholder={"Password"}
            value={password}
            type="password"
            setValue={setPassword}
          // Icon={<AiFillLock size={17} />}
          />

          <div className="flex relative items-center self-start">
            <input
              id="teal-checkbox"
              type="checkbox"
              checked={rememberMe}
              onChange={handleRememberMeChange}
              className="w-4 h-4 text-neutral-700 bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-600 focus:outline-none"
            />
            <label
              htmlFor="teal-checkbox"
              className="ml-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Remember Me
            </label>
          </div>
          <div className="flex w-full mt-5 text-center justify-center">
            <button
              className="font-bold py-2 px-8 w-[80%] mt-4 bg-gray-500 bg-opacity-20 rounded-md shadow border border-zinc-300 border-opacity-25 text-primary dark:text-white"
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
      </div>
      {isModalOpen && (
        <Modal title={modalTitle} content={modalContent} onClose={closeModal} />
      )}
    </div>
  );
};

export default Login;
