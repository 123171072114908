import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Dashboard from "./features/pages/dashboard";
import Login from "./features/pages/login";
import Body from "./features/pages/body";
import ConfirmBet from "./features/pages/confirmBet";
import Rules from "./features/pages/rules";
import ChangePassword from "./features/pages/changePassword";
import React from "react";
import NotFoundPage from "./features/pages/404";
import OldMatchesPage from "./features/pages/oldMatches";
import OldHistories from "./features/pages/oldHistory";
import Account from "./features/pages/account";
import Setting from "./features/pages/setting";
import MaunggComponent from "./features/pages/maungg";
import Gift from "./features/pages/gift";
import SelectedMatches from "./features/pages/selectedMatches";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { GoalScore } from "./features/pages/goalScore";
import { HotNews } from "./features/pages/hotNews";
import { Share } from "./features/pages/share";
import { useState } from "react";
import { SideBarTwo } from "./features/common/components/sidebar/sideBarTwo";
import Loading from "./features/common/components/loading";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SelectedMathesDetail from "./features/pages/selectedMatches/selectedMatchesDetails";
import { eventDataFetchInterval } from "./helpers/eventDataFetchInterval";
import { useDispatch } from "react-redux";
import NewsDetail from "./features/pages/hotNews/detail";

function MainContent() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showSideBar, setShowSideBar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showToastMessage = (message) => {
    toast(message, { position: 'bottom-left', autoClose: 1000, });
  }

  useEffect(() => {
    AOS.init();
    let stopFetching;
    if (location.pathname === '/maungg' || location.pathname === '/body') {
      stopFetching = eventDataFetchInterval(dispatch, location.pathname);
    } else {
      stopFetching && stopFetching();
    }

    return () => {
      stopFetching && stopFetching();
    };
  }, [dispatch, location.pathname]);

  const routes = [
    "/",
    "/dashboard",
    "/old-histories",
    "/old-matches",
    "/setting",
    "/body",
    "/confirm-bet",
    "/account",
    "/rules",
    "/maungg",
    "/gift",
    "/change-password",
    "/selected-matches",
    "/goal-score",
    "/hot-news",
    "/share"
  ];

  const themeColor = [
    "/dashboard",
    "/login",
  ]

  useEffect(() => {
    // start status bar color
    // const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    // console.log({prefersDarkMode})
    // const themeColorProperty = prefersDarkMode ? '--dark-mode-theme-color' : '--light-mode-theme-color';
    // const themeColor = getComputedStyle(document.documentElement).getPropertyValue(themeColorProperty);
    // const manifest = document.querySelector('link[rel="manifest"]');
    // if (manifest) {
    //   manifest.setAttribute('content', themeColor);
    // }
    // const themeColorMeta = document.querySelector('meta[name="theme-color"]');
    // if (themeColorMeta) {
    //   themeColorMeta.setAttribute('content', themeColor);
    // }
    // end status bar color
    const handleEvent = (e) => {
      e.preventDefault();
    };
    const images = document.querySelectorAll('img');
    images.forEach((image) => {
      image.addEventListener('touchstart', handleEvent);
    });
    // window.addEventListener("touchmove", handleEvent, { passive: false });
    window.addEventListener('contextmenu', handleEvent);
    // window.addEventListener('touchstart', handleEvent);
    // window.addEventListener('popstate', (event) => {
    //   // event.preventDefault();
    //   console.log('back click>', event)
    // });
    window.addEventListener('beforeunload', handleEvent);
    return () => {
      images.forEach((image) => {
        image.removeEventListener('touchstart', handleEvent);
      });
      // window.removeEventListener("touchmove", handleEvent);
      window.removeEventListener('contextmenu', handleEvent);
      // window.removeEventListener('touchstart', handleEvent);
      // window.removeEventListener('popstate', handleEvent);
      window.removeEventListener('beforeunload', handleEvent);
    };
  }, []);

  return (
    <div className="flex h-screen">
      {isLoading && <Loading />}
      {location.pathname !== '/login' &&
        location.pathname !== '/' &&
        location.pathname !== '/rules' &&
        routes.includes(location.pathname) &&
        (showSideBar || window.innerWidth >= 1024) && (
          <SideBarTwo setShowSideBar={setShowSideBar} />
        )}

      <div
        className={`w-full flex flex-1 h-screen ${location.pathname === '/login' || location.pathname.includes('/rule')
          ? 'ml-0'
          : ''
          } justify-center`}
      >
        <div
          className={`opacity-70 w-96 h-screen top-[-17px] absolute ${!themeColor.includes(location.pathname) ? 'hidden' : ''
            }`}
        >
          <div className=" -z-0 origin-top-left rotate-[5.68deg] opacity-70 w-52 h-10 left-[160.99px] top-[469.44px] absolute">
            <div className="-z-0 w-20 h-20 left-[55.85px] top-[16.40px] absolute origin-top-left rotate-[-1.74deg] bg-blue-600 rounded-full blur-3xl" />
            <div className="-z-0 w-24 h-14 left-[4.18px] top-[71.02px] absolute origin-top-left rotate-[-1.74deg] bg-sky-500 blur-3xl" />
            <div className="-z-0 w-16 h-12 left-[128.19px] top-[132.01px] absolute origin-top-left rotate-[5.68deg] bg-blue-600 rounded-full blur-3xl" />
          </div>
          <div className="-z-0 origin-top-left rotate-[24.86deg] opacity-70 w-52 h-96 left-[180.82px] top-[23px] absolute">
            <div className="-z-0 w-24 h-24 left-[49.51px] top-[38.99px] absolute origin-top-left rotate-[15.66deg] bg-blue-600 rounded-full blur-3xl" />
            <div className="-z-0 w-24 h-20 left-[-31.40px] top-[89.92px] absolute origin-top-left rotate-[15.66deg] bg-sky-500 blur-3xl" />
            <div className="-z-0 w-16 h-40 left-[66.16px] top-[207.12px] absolute origin-top-left rotate-[24.86deg] bg-blue-600 rounded-full blur-3xl" />
            <div className="-z-0 w-20 h-32 left-[-15.43px] top-[304.86px] absolute origin-top-left rotate-[24.86deg] bg-sky-500 blur-3xl" />
          </div>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={1000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        {/* Same as */}
        {/* <ToastContainer /> */}
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route
            path="/dashboard"
            element={<Dashboard setShowSideBar={setShowSideBar} setIsLoading={setIsLoading} />}
          />
          <Route
            path="/old-matches/:id"
            element={<OldMatchesPage setIsLoading={setIsLoading} />}
          />
          <Route path="/old-histories" element={<OldHistories setIsLoading={setIsLoading} />} />
          <Route path="/rules" element={<Rules setIsLoading={setIsLoading} />} />
          <Route path="/setting" element={<Setting setIsLoading={setIsLoading} />} />
          <Route
            path="/body"
            element={<Body isLoading={isLoading} setIsLoading={setIsLoading} showToastMessage={showToastMessage} />}
          />
          <Route
            path="/confirm-bet"
            element={
              <ConfirmBet setIsLoading={setIsLoading} showToastMessage={showToastMessage} />
            }
          />
          <Route
            path="/login"
            element={<Login setIsLoading={setIsLoading} showToastMessage={showToastMessage} />}
          />
          <Route
            path="/change-password"
            element={<ChangePassword setIsLoading={setIsLoading} />}
          />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/account" element={<Account setIsLoading={setIsLoading} />} />
          <Route
            path="/maungg"
            element={
              <MaunggComponent isLoading={isLoading} setIsLoading={setIsLoading} showToastMessage={showToastMessage} />
            }
          />
          <Route path="/gift" element={<Gift setIsLoading={setIsLoading} />} />
          <Route
            path="/selected-matches-detail/:id"
            element={<SelectedMathesDetail setIsLoading={setIsLoading} />}
          />
          <Route
            path="/selected-matches"
            element={<SelectedMatches setIsLoading={setIsLoading} />}
          />
          <Route path="/goal-score" element={<GoalScore setIsLoading={setIsLoading} />} />
          <Route path="/hot-news" element={<HotNews setIsLoading={setIsLoading} />} />
          <Route path="/hot-news/:id" element={<NewsDetail setIsLoading={setIsLoading} />} />
          <Route path="/share" element={<Share setIsLoading={setIsLoading} />} />
        </Routes>
      </div>
    </div>
  );
}

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    //eslint-disable-next-line
    if (localStorage.getItem("darkMode") == 'true') {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    i18n.changeLanguage(localStorage.getItem("language") === "en" ? "en" : "mm");

    if (localStorage.getItem("soundOn") == null || localStorage.getItem("soundOn") === undefined) {
      localStorage.setItem("soundOn", "true")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <MainContent />
    </Router>
  );
}

export default App;
