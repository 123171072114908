import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { playSong } from '../../common/actions/common.actions';
import dashboardService from "../../../api/services/dashboardService";
import { useTranslation } from "react-i18next";

export const HotNews = ({ setIsLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [currentNews, setCurrentNews] = useState([]);

  const handleSongClick = () => {
    dispatch(playSong());
  };

  const handleBackClick = () => {
    handleSongClick();
    navigate('/dashboard');
  };

  const handleSeeMoreClick = (news) => {
    navigate(`/hot-news/${news.hotNewsId}`, { state: { news } });
  };

  useEffect(() => {
    setIsLoading(true);
    dashboardService.getHotNews()
      .then(response => response.json())
      .then(data => {
        if (data && data.payload) {
          setCurrentNews(data.payload);
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div className="w-full h-screen flex flex-col dark:text-white dark:bg-black overflow-hidden relative">
      <div className="flex items-center p-2 border-b">
        <button
          className="top-3 left-3 w-10 h-10 mr-3
                    rounded-full drop-shadow-lg flex justify-center items-center dark:text-white text-primary text-4xl"
          onClick={handleBackClick}
        >
          <IoIosArrowBack size={25} className="" />
        </button>
        <h3 className="font-dangrek">HOT NEWS</h3>
      </div>
      <div className="flex flex-col flex-1 px-6 py-4 text-news overflow-auto">
        <span className="border-b pb-2 text-xl text-primary dark:text-white font-dangrek">What happened this week</span>
        {currentNews.map((news) => (
          <div key={news.hotNewsId} className="border-b py-2" onClick={() => handleSeeMoreClick(news)}>
            <h3 className="pb-4 text-lg leading-8 text-black text-justify dark:text-white">
              {news?.hotNewsTitle}
            </h3>
            <img src={news.hotNewsFilePath} alt="News" className="max-w-full h-auto pb-4" />
            <p className="pb-4 text-md text-justify text-news leading-7 indent-9">
              {/* {i18n.language === "en" ? (news?.hotNewsBody ? news.hotNewsBody.slice(0, 255) + ' ' : '') : (news?.hotNewsBodyMyan ? news.hotNewsBodyMyan.slice(0, 255) + ' ' : '')} */}
              {news?.hotNewsBody ? news.hotNewsBody.slice(0, 255) + ' ' : ''}
              <span className="text-sm font-bold">
              See More . . .
              </span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
