/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Marquee from "react-fast-marquee";
import { playSong, setBodyEvents, setEvents, setMixEvents } from '../../common/actions/common.actions';
import scan from "../../../assets/icons/client_management.svg";
import priceTag from "../../../assets/icons/price-tag.svg";
import shoeIcon from "../../../assets/icons/shoe.svg";
import prize from "../../../assets/icons/Prize.svg";
import newsPaper from "../../../assets/icons/Newspaper.svg";
import upArrow from "../../../assets/icons/uparrow.svg";
// import right from "../../../assets/icons/right.svg";
import soccer from "../../../assets/icons/Soccer.svg";
// import forwardArrow from "../../../assets/icons/forward-arrow.svg";
import dashboardService from "../../../api/services/dashboardService";
import withAuth from "../../common/hoc/withAuth";
import SideBarToggle from "../../common/components/sidebar/SideBarToggle";
import Logo from "../../common/components/Logo";
import Popup from "./popup";
import PopupModal from './popupModal';
import { useTranslation } from "react-i18next";
import DarkThemeToggleComponent from "../../../darkThemeToggle";
import { BiFootball } from "react-icons/bi";
import { setConfirmData } from '../../common/actions/common.actions';
import { selectLoginData } from "../login/login.actions";
// import { AiOutlineScan } from "react-icons/ai";
// import { PiCurrencyDollarSimpleBold } from "react-icons/pi";
// import { GiRunningShoe } from "react-icons/gi";
// import { PiArrowLineUpRightBold } from "react-icons/pi";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
// import { GiSoccerKick } from "react-icons/gi";
import { IoMdShareAlt } from "react-icons/io";
// import { PiMedalFill } from "react-icons/pi";
// import { LuNewspaper } from "react-icons/lu";
import LogoIcon from "../../../assets/icons/logoNew.png";
import signalRConnection from '../../../signalR/signalRService';

function Dashboard({ setShowSideBar, setIsLoading }) {
  const dispatch = useDispatch();
  const [displayTextArr, setDisplayTextArr] = useState([]);
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { userDetails } = useSelector(selectLoginData);
  const [userBalance, setUserBalance] = useState(userDetails?.balance);
  const navigate = useNavigate();

  useEffect(() => {
    const handleChangedBalance = async (user, data) => {
      console.log(`changedBalance Event: ${user}: ${data}`);
      // Handle process after changedBalance
      const userDetailTmp = userDetails || {};
      await updateUserBalance(userDetailTmp);
    };
    signalRConnection.on("changedBalance", handleChangedBalance);
    // Cleanup the event handler when the component unmounts
    return () => {
      signalRConnection.off("changedBalance", handleChangedBalance);
    };
  }, [signalRConnection, userDetails]);
  

  // add akn for click song
  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleConfirmData = (data) => {
    dispatch(setConfirmData(data));
  };

  const handlePopupToggle = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const removeConfirmData = () => {
    handleConfirmData(null);
  };

  const initData = async () => {
    try {
      // setIsLoading(true);
      await removeConfirmData();
      // const userDetailTmp =
      //   (await JSON.parse(localStorage.getItem("userDetails"))) || {};
      const userDetailTmp = userDetails || {};
      const getDisplayEvents = async () => {
        const data = await dashboardService.getDisplayTextForDashboard().json();
        setDisplayTextArr(data);
      };
      await getDisplayEvents();
      await updateUserBalance(userDetailTmp);
    } catch (error) {
      console.log({ error });
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    initData();
    dispatch(setMixEvents([]));
    dispatch(setBodyEvents([]));
    dispatch(setEvents([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const storedUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (storedUserDetails && storedUserDetails?.balance) {
      setUserBalance(storedUserDetails?.balance);
    }
  }, []);

  const updateUserBalance = async (userDetailTmp) => {
    if (!userDetailTmp.userId) return;
    try {
      const userId = Number(userDetailTmp.userId);
      const res = await dashboardService.getBalanceForDashboard(userId)
        .then((response) => response.json());
      if (res) {
        if (res && res.userInfo) {
          setUserBalance(res?.userInfo[0]?.balance);
          localStorage.setItem("userDetails", JSON.stringify(res?.userInfo[0]));
        }
      }
    } catch (error) {
      console.error("Error updating user balance:", error);
    }
  };

  return (
    <div className="flex flex-row w-full h-screen bg-transparent dark:bg-background">
      <SideBarToggle setShowSideBar={setShowSideBar} />
      <div className="z-40 absolute cursor-pointer top-3 right-3 mt-2 mr-3">
        <DarkThemeToggleComponent />
      </div>
      {/* <Popup data={displayTextArr} /> */}
      <PopupModal data={displayTextArr} isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}/>
        <div className="flex flex-col justify-center items-center h-full w-full md:w-[60vw] lg:w-[30vw] mx-auto px-10 sm:px-4">
        <Logo />
        <div data-modal-target="popup-modal" data-modal-toggle="popup-modal" className="w-full h-8 cursor-pointer z-40 md:w-full flex justify-center  text-center mt-3 mb-1 px-2 py-1"
          onClick={handlePopupToggle}
        >
          <div className="w-full align-middle justify-center text-left overflow-hidden" dir="ltr">
            <div className="w-full font-bold text-black drop-shadow-md dark:text-white whitespace-nowrap">
              {/* <div id="scroll-text" className="overflow-hidden">
                {displayTextArr.map(d => d.displayText).join(' ')}
              </div> */}
              <Marquee>
                {displayTextArr.map(d => d.displayText).join(' ')}
              </Marquee>
            </div>
          </div>
        </div>

        <div className="z-40 w-full font-myanmarKhay px-1" data-aos="fade-down">
          <div
            className="w-full h-[10vh] flex bg-dashboard justify-between items-center rounded text-white px-5 md:px-10 "
          >
            <div className="ml-2">
              <img src={scan} alt='scan'></img>
              {/* <AiOutlineScan size={28} /> */}
            </div>
            <div className="">{parseInt(userBalance).toLocaleString()} Ks</div>  {/* text-sm xs:text-base */}
            <div className=" cursor-pointer self-start pt-3" onClick={() => {
              handleSongClick();
              navigate('/account');
            }}>
              <img src={priceTag} alt='pricetag'></img>
              {/* <PiCurrencyDollarSimpleBold size={22} /> */}
            </div>
          </div>
        </div>


        <div className="flex py-2 w-full px-1">
          <div className="flex flex-col flex-grow">

            <div className="z-40 w-full" data-aos="fade-up">
              <div className="h-[10vh] cursor-pointer flex bg-dashboard justify-center items-center rounded text-white mb-2 px-5 md:px-10"
                onClick={(e) => {
                  handleSongClick().then(() => {
                    navigate('/maungg');
                  });
                }}
              >
                <img className="mr-5" src={shoeIcon} alt='shoe'></img>
                {/* <GiRunningShoe size={26} className="mr-5" /> */}
                <div className="text-sm xs:text-base">{t("maungg")}</div>
              </div>
            </div>
            <div className="z-40 w-full" data-aos="fade-right">
              <div className="h-[10vh] flex cursor-pointer bg-dashboard justify-center items-center rounded text-white px-5 md:px-10"
                onClick={(e) => {
                  handleSongClick().then(() => {
                    navigate('/body');
                  });
                }}
              >
                <BiFootball size={26} className="text-white mr-5" />
                <div className="text-center text-sm xs:text-base">{t("body")}<br />{t("totalGoals")}</div>
              </div>
            </div>
          </div>

          <div className="flex flex-1 flex-shrink">
            <div className="z-40 w-full" to={'/gift'} data-aos="fade-left">
              <div className="h-full cursor-pointer flex flex-col bg-dashboard justify-center text-center items-center rounded text-white sm:px-5 px-0 ml-2"
              // onClick={(e) => {
              //   handleSongClick().then(() => {
              //     navigate('/gift');
              //   });
              // }}
              >
                <img src={LogoIcon} alt="logo" />
                {/* <img src={prize} alt='prize'></img> */}
                {/* <PiMedalFill size={28} className="text-gift mb-1" /> */}
                {/* <div className="text-gift text-sm italic bg-clip-text bg-gradient-to-r font-inter">Super Sunday Gift</div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full px-1">

          <div className="flex flex-1 flex-shrink" data-aos="fade-up-right">
            <div className="z-40 w-full">
              <div className="h-full cursor-pointer flex flex-col bg-dashboard justify-center text-center items-center rounded text-white sm:px-5 px-0 mr-2"
                onClick={(e) => {
                  handleSongClick().then(() => {
                    navigate('/hot-news');
                  });
                }}
              >
                <img src={newsPaper} alt='newspaper'></img>
                {/* <LuNewspaper size={26} /> */}
                <div className="text-white text-sm xs:text-base bg-clip-text bg-gradient-to-r mt-8 mb-4 transform -rotate-90 whitespace-nowrap">Hot News</div>
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-grow">
            <div className="z-40 w-full" data-aos="fade-up-left">
              <div className="h-[10vh] flex cursor-pointer bg-dashboard justify-center items-center rounded text-white mb-2 sm:px-5 px-0 md:px-10"
                onClick={(e) => {
                  handleSongClick().then(() => {
                    navigate('/selected-matches');
                  });
                }}
              >
                <img className="mr-5" src={upArrow} alt='up-arrow'></img>
                {/* <PiArrowLineUpRightBold size={26} className="mr-5" /> */}
                <div className="text-center text-sm xs:text-base">{t("selected")}<br />{t("matches")}</div>
              </div>
            </div>
            <div className="z-40 w-full" data-aos="fade-down-right">
              <div className="h-[10vh] cursor-pointer flex bg-dashboard justify-center items-center rounded text-white xs:px-10 px-0 md:px-10"
                onClick={(e) => {
                  handleSongClick().then(() => {
                    navigate('/old-histories');
                  });
                }}
              >
                <div>
                  {/* <img className="mr-5" src={right} alt='right'></img> */}
                  <IoCheckmarkDoneSharp size={26} className="mr-1" />
                </div>
                <div className="text-center text-sm xs:text-base">{t("oldHistories")}</div>
              </div>
            </div>

          </div>
        </div>

        <div className="w-full flex py-2 px-1">
          <Link className="z-40 w-full">
            <div className="h-[10vh] cursor-pointer flex flex-grow bg-dashboard justify-center items-center rounded text-white mr-2 px-5 md:px-10 sm:px-5"
              onClick={(e) => {
                handleSongClick().then(() => {
                  navigate('/goal-score');
                });
              }}
            >
              <img className="mr-5" src={soccer} alt='soccer'></img>
              {/* <GiSoccerKick size={26} className="mr-5" /> */}
              <div className="">{t("goalResult")}</div>
            </div>
          </Link>
          <div className="z-40 w-full" to={'/share'}>
            <div className="h-[10vh] cursor-pointer flex flex-col flex-1 flex-shrink bg-dashboard justify-center text-center items-center rounded text-white"
              onClick={(e) => {
                handleSongClick().then(() => {
                  navigate('/share');
                });
              }}
            >
              {/* <img src={forwardArrow} alt='forward'></img> */}
              <IoMdShareAlt size={26} />
              <div className="">{t("share")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuth(Dashboard);