import React, { useCallback, useEffect, useState } from "react";
import backArrow from "../../../assets/icons/Back.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import sortIcon from "../../../assets/icons/sort.svg";
import { GoalScoreCard } from "./goalScoreCard";
import whiteSchedule from "../../../assets/icons/whiteSchedule.svg";
import goalScoreService from "../../../api/services/goalScoreService";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import { useMemo } from "react";
import { useDispatch } from 'react-redux';
import { playSong } from '../../common/actions/common.actions';
import { FiSearch } from "react-icons/fi";
import clear from "../../../assets/icons/clear.png";
import { BiSolidCalendar } from "react-icons/bi";
import { LiaSortAmountDownSolid } from "react-icons/lia";
import { MdCancel } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";

export const GoalScore = ({ setIsLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [goalScore, setgoalScore] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sortBy, setSortBy] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [filteredGoalScore, setFilteredGoalScore] = useState([]);
  //eslint-disable-next-line
  const navigate = useNavigate();

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleBackClick = () => {
    handleSongClick();
    navigate("/dashboard");
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Filter the goal score based on the search input
    const filteredData = goalScore.filter(item => {
      const searchTerm = searchInput?.toLowerCase();
      return (
        item.league.toLowerCase().includes(searchTerm) ||
        item.homeTeam.toLowerCase().includes(searchTerm) ||
        item.homeTeamMyan.toLowerCase().includes(searchTerm) ||
        item.awayTeam.toLowerCase().includes(searchTerm) ||
        item.awayTeamMyan.toLowerCase().includes(searchTerm)
      )
    }
    );
    setFilteredGoalScore(filteredData);
  }, [searchInput, goalScore]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    getData(date);
  };

  const handleSearchClick = async () => {
    await handleSongClick();
    setShowSearch(!showSearch);
  };

  const handleSearchInputChange = useCallback((event) => {
    setSearchInput(() => event.target.value);
  }, []);

  const handleClearSearch = () => {
    handleSongClick();
    setSearchInput(''); // Clear the search input
    setShowSearch(false);
  };

  const getData = (date = selectedDate) => {
    setIsLoading(true);
    goalScoreService
      .goalScores({
        startDate: moment(new Date(date)).format("YYYY-MM-DD"),
        endDate: moment(new Date(date)).format("YYYY-MM-DD"),
      })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          const teamData = data?._data.map((item) => item.team).flat();
          setgoalScore(() => teamData);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("API request error:", err);
      });
  };

  const sortedGoalScore = useMemo(() => {
    const sortedData = [...filteredGoalScore];
    if (sortBy) {
      sortedData.sort((a, b) => new Date(b.event) - new Date(a.event));
    } else {
      sortedData.sort((a, b) => new Date(a.event) - new Date(b.event));
    }
    return sortedData;
  }, [filteredGoalScore, sortBy]);

  const handleSortingMatches = () => {
    setSortBy((preSort) => !preSort);
  };
  return (
    <div className="w-full h-screen flex flex-col dark:bg-bg_header_dark dark:text-white overflow-hidden">
      <div className="w-full flex justify-between items-center py-2 border-b dark:shadow-sm bg-bg_header dark:border-b-2 dark:border-slate-700 dark:bg-bg_header_dark">
        <div className="flex items-center">
          <div onClick={handleBackClick}>
            <button
              className="top-3 left-5 w-10 h-10
                        rounded-full drop-shadow-lg flex justify-center items-center text-blue-700 text-4xl"
            >
              {/* <img src={backArrow} alt="Back Arrow" /> */}
              <IoIosArrowBack size={25} className="text-white" />
            </button>
          </div>
          {showSearch ?
            ''
            :
            (
              <h3 className="text-white text-lg ml-3 text-center font-dangrek">
                {t("goalScore")}
              </h3>
            )}
        </div>
        {showSearch ? (
          <div className="w-full relative flex items-center mr-2">
            <input
              type="text"
              className="border font-breeSerif bg-search w-full p-2 pl-10 focus:outline-none focus:ring-0 rounded-3xl"
              placeholder="Search"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            <FiSearch size={18} className="absolute left-4 dark:text-black" />
            <button
              className="absolute right-4"
              onClick={handleClearSearch}
            >
              {/* <img src={clear} alt="Clear" className="w-5 h-5" /> */}
              <MdCancel size={21} className="text-gray-400" />
            </button>
          </div>
        ) : (
          ''
        )}
        <div className="flex flex-row justify-end items-center">
          {showSearch ?
            ''
            :
            (
              <div className="flex justify-center items-center px-2 rounded shadow-lg">
                <label htmlFor="goalScoreDate">
                  {/* <img
                    src={whiteSchedule}
                    alt="WhiteSchedule"
                    className=" opacity-80"
                  /> */}
                  <BiSolidCalendar size={24} className="text-white opacity-80" />
                </label>
                <DatePicker
                  id="goalScoreDate"
                  selected={selectedDate || new Date()}
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  withPortal
                  closeOnSelect={true}
                  className="w-2 h-9 hidden border-none bg-inherit text-white focus:outline-none  text-transparent dark:text-transparent"
                />
              </div>
            )
          }
          {showSearch ?
            ''
            :
            (
              <div className="px-2 py-1 rounded-md" onClick={handleSortingMatches}>
                {/* <img onClick={handleSortingMatches} src={sortIcon} alt="Sort" /> */}
                <LiaSortAmountDownSolid size={24} className="text-white" />
              </div>
            )
          }
          {
            showSearch ? (
              ''
            ) : (
              <button onClick={handleSearchClick} className='text-white px-2'>
                <FiSearch size={21} />
              </button>
            )
          }
        </div>
      </div>
      <div className="flex-1 overflow-auto" data-aos="fade-up">
        <GoalScoreCard data={sortedGoalScore} />
      </div>
    </div>
  );
};
