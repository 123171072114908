import React from 'react';
// import blackShoe from "../../../assets/icons/blackshoe.svg";
// import shoeIcon from "../../../assets/icons/shoe.svg";
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import { BiFootball } from 'react-icons/bi';
import { GiRunningShoe } from 'react-icons/gi';

const Card = ({ data }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className='flex flex-col py-5 px-4 m-3 rounded-lg h-max dark:border dark:border-white/[0.5] dark:text-white shadow-md border-2 border-slate-100 font-myanmarSansPro'>
      <div className='flex justify-between items-center'>
        <div className='flex'>
          {/* <span className="dark:hidden pr-3">
            <img src={blackShoe} alt="Black Shoe"></img>
          </span>
          <span className="hidden dark:block px-2">
            <img src={shoeIcon} alt='Shoe'></img>
          </span> */}
          {data.gamblingTypeId === 2 && <GiRunningShoe size={24} className="mr-1" />}
          {data.gamblingTypeId === 1 && <BiFootball size={24} className="mr-1" />}
          <h2 className='text-football dark:text-league_text font-bold ml-1'>{data?.gamblingTypeId === 1 ? t("body") : t("maungg")}</h2>
        </div>
        <p className="text-sm text-league_text font-dangrek">
          {
            moment(new Date(data?.createdDate)).format('DD.MM.YYYY h:mm A')
          }
        </p>
      </div>
      {/* <div className='grid grid-cols-2 gap-4 pl-8 py-1 items-center'>
        <h4 className={`text-bg_header dark:text-white font-bold `}>BetID</h4>
        <p className=" dark:text-league_text text-sm cols-span-2 text-selectMatch">{data?.postingNo}</p>
      </div> */}
      <div className='grid grid-cols-2 gap-4 pl-8 py-1 pt-2 mt-2'>
        <h4 >{t("betAmount")}</h4>
        <p className='font-dangrek'>{parseInt(data?.amount).toLocaleString()}</p>
      </div>
      {
        data?.gamblingTypeId === 2 ?
          <div className='grid grid-cols-2 gap-4 pl-8 py-1'>
            <h4 >{t("teamCount")}</h4>
            <p className='font-dangrek'>{data?.teamCount}</p>
          </div>
          : null
      }
      <div className='grid grid-cols-2 gap-4 pl-8 py-1'>
        <h4 >{t("profitAmount")}</h4>
        <p className='font-dangrek'>{data?.profitAmount < 0 ? "0" : parseInt(data?.profitAmount).toLocaleString()}</p>
      </div>

      <div className={`flex justify-end tracking-wider font-dangrek ${data?.status === 'Win' ? 'text-win' : 'text-lose'}`}>
        <p className='font-extrabold'>{data?.status}</p>
      </div>
    </div>
  );
};

export default Card;
