import React from "react";

const TextInputFloat = ({
  id,
  type = "text",
  label,
  placeholder,
  value,
  setValue,
  error = null,
  Icon = null,
}) => {
  return (
    <div className="w-full">
      <div className="relative w-full">
        <input
          type={type}
          required
          value={value}
          onChange={(e) => setValue(e.target.value)}
          id={`floating_outlined${id}`}
          className={`block shadow py-2.5 ${Icon ? "pl-8" : "pl-4"
            } pr-4 w-full text-sm text-gray-900 bg-transparent
                 rounded-md border border-zinc-300 appearance-none dark:text-white 
                 dark:border-gray-600 dark:focus:border-primary focus:outline-none 
                 focus:ring-0 focus:border-primary peer`}
          placeholder={placeholder}
        />
        <label
          htmlFor={`floating_outlined${id}`}
          className={`absolute ${Icon ? "pl-8" : "pl-4"
            } text-sm text-gray-500 dark:text-white duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-focus:bg-[#EBEEF2] dark:peer-focus:bg-primary
                dark:bg-primary px-2 peer-focus:px-2 peer-focus:text-primary dark:peer-focus:text-white peer-placeholder-shown:scale-100 
                peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 
                peer-focus:scale-75 peer-focus:-translate-y-4 left-1 `}
        >
          {label}
        </label>
        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-primary dark:text-white">
          {Icon}
        </span>
      </div>
      {error && (
        <p
          id="outlined_error_help"
          className=" text-xs font-extralight text-red-600 dark:text-red-400"
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default TextInputFloat;
