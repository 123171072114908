import { useState } from 'react';
import { useNavigate } from 'react-router';
import TextInputFloat from '../../common/components/textInput/TextInputFloat';
import Logo from '../../common/components/Logo';
import login_Service from '../../../api/services/login_Service';
import forwardArrow from '../../../assets/icons/forward.svg';
import { playSong } from '../../common/actions/common.actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectLoginData } from "../login/login.actions";
import Modal from '../../common/components/modal';
import { IoIosArrowBack } from "react-icons/io";

const ChangePassword = ({ setIsLoading }) => {
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const { userDetails } = useSelector(selectLoginData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('Change Passowrd Success');

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (oldPassword !== '' && newPassword !== '' && confirmPassword !== '') {
      if (newPassword === confirmPassword) {
        try {
          setIsLoading(true);
          setIsModalOpen(true);
          await login_Service.changePassword({
            userId: Number(userDetails.userId),
            CurrentPassword: oldPassword,
            NewPassword: newPassword,
            ConfirmPassword: confirmPassword,
          });
          setIsSuccess(true);
          setMessage('Change Passowrd Success');
          setIsLoading(false);
          setOldPassword('');
          setNewPassword('');
          setConfirmPassword('');
          // setTimeout(() => {
          setIsModalOpen(true);
          // }, 1000);
        } catch (error) {
          setIsSuccess(false);
          setIsLoading(false);
          setMessage('Change password Fail!');
          setIsModalOpen(true);
          console.error('API request error:', error);
        }
      } else {
        setIsSuccess(false);
        setIsModalOpen(true);
        setMessage('New password and confirm password must match.');
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (isSuccess) {
      navigate('/dashboard');
    }
  };

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleClick = () => {
    handleSongClick();
    navigate('/setting');
  };

  return (
    <div className="flex flex-col w-full h-full items-center bg-white dark:bg-background bg-opacity-90 font-dangrek">
      <div className="w-full flex justify-between items-center text-center pr-5 mt-4">
        <div className="flex items-center pr-2">
          <button
            className="top-3 left-3 w-10 h-10 mr-3
                    rounded-full drop-shadow-lg flex justify-center items-center text-blue-700 text-4xl"
            onClick={handleClick}
          >
            {/* <img src={forwardArrow} alt="Back Arrow" /> */}
            <IoIosArrowBack size={25} className="text-content" />
          </button>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <Logo />
        <div className="z-50 flex flex-col mt-10 mx-4 sm:mx-0 h-[60vh] bg-white/[0.2] dark:backdrop-blur-sm backdrop-blur-sm dark:bg-primary/[0.2] dark:bg-opacity-30 bg-opacity-30 rounded-md shadow border border-white border-opacity-20">
          <form
            onSubmit={handleChangePassword}
            className="flex flex-col justify-center items-center h-full rounded-b-md shadow-md px-8 md:px-16 space-y-4"
          >
            <TextInputFloat
              id={'oldPassword'}
              placeholder={'Old Password'}
              value={oldPassword}
              type="password"
              setValue={setOldPassword}
            />
            <TextInputFloat
              id={'newPassword'}
              placeholder={'New Password'}
              value={newPassword}
              type="password"
              setValue={setNewPassword}
            />
            <TextInputFloat
              id={'confirmPassword'}
              placeholder={'Confirm Password'}
              value={confirmPassword}
              type="password"
              setValue={setConfirmPassword}
            />
            <div className="flex w-full mt-5 text-center justify-center">
              <button
                className="font-bold py-2 px-8 w-[80%] mt-4 bg-gray-500 bg-opacity-20 rounded-md shadow border border-zinc-300 border-opacity-25 text-primary dark:text-white"
                type="submit"
              >
                OK
              </button>
            </div>
          </form>
        </div>
      </div>
      {isModalOpen && (
        <Modal title="SPORT 909" content={message} onClose={closeModal} />
      )}
    </div>
  );
};

export default ChangePassword;
