import ky from "ky";
import { apiList } from "./apiList";
import { handleApiError } from "./apiUtils";

const client = ky.extend({
  prefixUrl: apiList.baseUrl,
  timeout: false,
  hooks: {
    beforeRequest: [
      (request) => {
        // Do something before every request
        // This is a good place to authorize request if needed
        request.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
      }
    ],
    afterResponse: [
      async (request, options, response) => {
        console.log('status is =>', response.status);
        await handleApiError(response);
      }
    ]
  }
});

export default client;