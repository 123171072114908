import React from 'react';

const Sort = ({ onClose, onSortingSelection }) => {

  const handleSortByChange = (value) => {
    onSortingSelection(value);
    onClose();
  };

  return (

    <div >
      <div className={`border-b border-black/[0.5] dark:border-white/[0.3] pb-2 pl-4 pr-14 `}>
        <p
          className="cursor-pointer"
          onClick={() => handleSortByChange('eventTime')}
        >
          Sort by Time
        </p>
      </div>
      <div className={`border-b border-black/[0.5] dark:border-white/[0.3] py-2 pl-4 pr-14 `}>
        <p
          className="cursor-pointer"
          onClick={() => handleSortByChange('leagueName')}
        >
          Sort by League
        </p>
      </div>
    </div>
  );
};

export default Sort;
