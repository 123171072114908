import React, { useEffect } from "react";
// import Song from "../../../../assets/sound/newSong.mp3";
import { useDispatch } from "react-redux";
import { playSong } from "../../actions/common.actions";

const SideBarToggle = ({ setShowSideBar }) => {
  const dispatch = useDispatch();

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  useEffect(() => {
  }, []);

  return (
    <button
      type="button"
      className="toggle absolute inline-flex z-999 items-center top-3 left-3 w-10 h-10 p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      onClick={() => {
        setShowSideBar(true);
        handleSongClick();
      }}
    >
      <span className="sr-only">Open sidebar</span>
      <svg
        className="w-6 h-6 toggle"
        aria-hidden="false"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="toggle"
          clipRule="evenodd"
          fillRule="evenodd"
          d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
        ></path>
      </svg>
    </button>
  );
}

export default SideBarToggle;
