import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import forwardArrow from '../../../assets/icons/forward.svg';
import Card from './card';
import whiteSchedule from '../../../assets/icons/whiteSchedule.svg';
import { useEffect } from 'react';
import oldHistoryService from '../../../api/services/oldHistoryService';
import * as moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setOldHistory } from '../../common/components/sidebar/sidebar.actions';
import { useDispatch, useSelector } from 'react-redux';
import { playSong, setOldHistoryDate, setOldHistoryDateOption } from '../../common/actions/common.actions';
import { BiSolidCalendar } from "react-icons/bi";
import { IoIosArrowBack } from "react-icons/io";

const OldHistories = ({ setIsLoading }) => {
  const dispatch = useDispatch();
  const [oldHistories, setOldHistories] = useState([]);
  const { oldHistoryDate, oldHistoryDateOption } = useSelector((state) => state.common);
  console.log({ oldHistoryDate, oldHistoryDateOption })
  const initialStoredDate = oldHistoryDate ? new Date(oldHistoryDate) : new Date();
  const [selectedDate, setSelectedDate] = useState(initialStoredDate);
  const [histories, setHistories] = useState(true);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleBackClick = () => {
    const newDate = new Date();
    dispatch(setOldHistoryDate(newDate.toISOString()));
    handleSongClick();
    navigate('/dashboard');
  };

  useEffect(() => {
    const handleEvent = (e) => {
      e.preventDefault();
      const newDate = new Date();
      dispatch(setOldHistoryDate(newDate.toISOString()));
    };
    window.addEventListener('popstate', handleEvent);
    return () => {
      window.removeEventListener('popstate', handleEvent);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let startDate, endDate;
    if (oldHistoryDateOption === 'choose') {
      startDate = moment(oldHistoryDate).format('YYYY-MM-DD');
      endDate = moment(oldHistoryDate).format('YYYY-MM-DD');
    } else if (oldHistoryDateOption === 'today') {
      startDate = moment(new Date()).format('YYYY-MM-DD');
      endDate = moment(new Date()).format('YYYY-MM-DD');
      // setSelectedDate(startDate);
    } else if (oldHistoryDateOption === 'yesterday') {
      startDate = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
      endDate = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
    } else {
      startDate = moment(selectedDate).format('YYYY-MM-DD');
      endDate = moment(selectedDate).format('YYYY-MM-DD');
    }
    oldHistoryService
      .bettingOldHistory({
        startDate: startDate,
        endDate: endDate,
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'NO Data Found!!') {
          // Handle the case where no data is found
          setHistories(false);
        } else if (data) {
          // const filteredData = data.filter((history) =>
          //   moment(history.createdDate).isSame(endDate, 'day'),
          // );
          setOldHistories(data);
          setHistories(data.length > 0); // Check if there are transactions
        } else {
          setHistories(false); // No transactions available
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error('API request error:', err);
      });
  }, [navigate, setIsLoading, selectedDate, oldHistoryDateOption]);

  const handleDateChange = (date) => {
    dispatch(setOldHistoryDate(date.toISOString()));
    dispatch(setOldHistoryDateOption('choose'));
    setSelectedDate(date);
  };


  const handleSelectedOptionChange = (value) => {
    dispatch(setOldHistoryDateOption(value));
  }

  return (
    <div className="w-full flex flex-col h-screen bg-white/[0.2] dark:bg-bg_header_dark overflow-hidden">
      <div className="flex justify-between items-center py-2 bg-k_teamsBgColor dark:bg-bg_header_dark border-b shadow-sm dark:border-b dark:border-border_dark/[0.7]">
        <button
          className="top-3 left-3 w-10 h-10 mr-3
          rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl"
          onClick={handleBackClick}
        >
          {/* <img src={forwardArrow} alt="Back Arrow" /> */}
          <IoIosArrowBack size={25} className="text-white" />
        </button>
        <div className='flex flex-1'>
          <h3 className={`text-white `}>{t('oldHistories')}</h3>
        </div>
        <div className="flex justify-center items-center px-1 mr-1 rounded">
          <label htmlFor="oldHistoryDate">
            {/* <img
              src={whiteSchedule}
              alt="WhiteSchedule"
              className="opacity-80 pr-3"
            /> */}
            <BiSolidCalendar size={24} className="text-white opacity-80 mr-3" />
          </label>
          <DatePicker
            id="oldHistoryDate"
            selected={selectedDate || new Date()}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            withPortal
            closeOnSelect={true}
            className="w-2 h-9 hidden border-none bg-inherit text-white focus:outline-none  text-transparent dark:text-transparent"
          />
        </div>
      </div>
      <div className="flex-1 overflow-auto">
        <div className="flex justify-end pt-3 pr-2">
          <div className="relative dark:border dark:border-white/[0.5] rounded mr-3">
            <select
              className="block dark:text-white text-sm dark:bg-bg_header_dark appearance-none shadow-sm drop-shadow-md w-full border-1 text-gray-700 py-3 px-12 pr-12 rounded leading-tight border-gray-400 focus:ring-transparent focus:outline-none focus:bg-white"
              value={oldHistoryDateOption}
              onChange={(e) => handleSelectedOptionChange(e.target.value)}
            >
              <option value="choose">Choose</option>
              <option value="today">{t('today')}</option>
              <option value="yesterday">{t('yesterday')}</option>
            </select>
          </div>
        </div>
        <div className={`z-40 w-full ${histories ? '' : 'contents'}`}>
          {histories ? (
            oldHistories?.map((data, index) => (
              <Link
                key={index}
                to={`/old-matches/${data?.gamblingId}`}
                onClick={() => {
                  dispatch(setOldHistory(data));
                  handleSongClick();
                }}
              >
                <Card data={data} />
              </Link>
            ))
          ) : (
            <div className="flex flex-1 justify-center items-center h-full">
              <p className="text-xs dark:text-white">{t('noData')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OldHistories;
