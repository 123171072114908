/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import backArrow from "../../../assets/icons/Back.svg";
import Card from "./card";
import { useNavigate } from "react-router-dom";
import bettingService from "../../../api/services/bettingService";
import { useTranslation } from "react-i18next";
import Modal from "../../common/components/modal/index"
import { useDispatch } from "react-redux";
import { playSong, setBodyEvents, setEvents, setMixEvents } from "../../common/actions/common.actions";
import { useSelector } from 'react-redux';
import { selectLoginData } from "../login/login.actions";
import { setConfirmData } from '../../common/actions/common.actions';
import { IoIosArrowBack } from "react-icons/io";

const ConfirmBet = ({ onClose, showToastMessage, setIsLoading }) => {
  const { confirmData } = useSelector((state) => state.common);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [predictAmount, setPredictAmount] = useState(0);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [oddList, setOddList] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const { userDetails } = useSelector(selectLoginData);

  const getData = () => {
    setUserData(userDetails);
  }

  useEffect(() => {
    console.log({ confirmData })
    if (userData && confirmData) {
      userBettingCheck();
      predictProfit();
    }
  }, [userData, confirmData]);

  const userBettingCheck = async () => {
    try {
      const payload = {
        userId: userData.userId,
        Odd: confirmData?.selectedMatches?.reduce((a, c) => {
          a.push({ eventId: c.eventId });
          return a;
        }, []),
      };
      const res = await bettingService
        .userBettingCheck(payload)
        .then((response) => response.json());
      if (res && res.odd && res.odd.length > 0) {
        const changeEventList = res.odd.reduce((a, c) => {
          a.push(c.eventId);
          return a;
        }, []);
        setOddList(changeEventList);
      }
      return res;
    } catch (e) {
      console.log("Error :", e);
    }
  };

  const predictProfit = async () => {
    try {
      const payload = {
        gamblingAmount: Number.parseInt(confirmData.betAmount),
        teamCount: confirmData.selectedMatches.length,
        isFivePercent: confirmData.selectedMatches[0].isFivePercent,
      };
      const res = await bettingService
        .predictProfit(payload)
        .then((response) => response.json());
      if (res) {
        setPredictAmount(res.predictAmount);
      }
    } catch (e) {
      console.log("Error :", e);
    }
  };

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleConfirmData = async (data) => {
    dispatch(setConfirmData(data));
  };

  const goBack = () => {
    handleSongClick();
    navigate(-1);
  };

  const checkValidate = async () => {
    const checkData = await userBettingCheck();
    if (checkData?.islock) {
      localStorage.removeItem('token');
      localStorage.removeItem('userDetails');
      localStorage.removeItem('confirmData');
      navigate("/login");
      return
    }
    if (checkData) {
      const currentTime = new Date(checkData.serverTime?.currentTime);
      const userInfo = checkData.userInfo[0];
      if (confirmData && confirmData.selectedMatches && confirmData.selectedMatches.length > 0) {
        if (Number.parseInt(confirmData.betAmount) > userInfo.balance) {
          return 'User balance is lower than bet amount!';
        }
        if (confirmData.isMix && confirmData.selectedMatches.length < 2) {
          return 'Please select at least 2 match!';
        }
        const error = await confirmData.selectedMatches.reduce((a, c) => {
          if (new Date(c.eventTime) <= currentTime) {
            a = `${c.homeTeam} and ${c.awayTeam} match is over time!`;
          }
          return a;
        }, '');
        return error;
      } else {
        return 'Please select at least 1 match!';
      }
    } else {
      return '';
    }
  }

  const confirmBetting = async () => {
    if (isButtonDisabled) return;
    setIsLoading(true);
    if (!confirmData) {
      navigate(-1);
      return;
    };
    setIsButtonDisabled(true);
    const errorMsg = await checkValidate();
    if (errorMsg) {
      if (errorMsg.includes("match is over time!")) {
        handleConfirmData(null);
      }
      setIsLoading(false);
      openModal('Sport 909', errorMsg);
      return;
    }
    handleSongClick();
    try {
      const date = new Date();
      const yearLastTwoDigits = date.getFullYear() % 100;
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hour = String(date.getHours()).padStart(2, '0');
      const minute = String(date.getMinutes()).padStart(2, '0');
      const second = String(date.getSeconds()).padStart(2, '0');

      const postNo = 'GB'+userData.roleId.toString() + userData.userId.toString() + yearLastTwoDigits.toString() +
        month.toString() + day.toString() + hour.toString() + minute.toString() + second.toString();
      const payload = {
        postingNo: postNo,
        gamblingTypeId: confirmData.selectedMatches.length === 1 ? 1 : 2,
        teamCount: confirmData.selectedMatches.length,
        amount: Number.parseInt(confirmData.betAmount),
        active: true,
        selectedDate: confirmData.selectedMatches[0].selectedDate,
        userId: userData.userId,
        details: confirmData.selectedMatches.reduce((a, c) => {
          const obj = {
            eventId: c.eventId,
            rapidEventId: c.rapidEventId,
            leagueId: c.leagueId,
            footballTeamId: c.choosen === 'home' ? c.homeId : c.choosen === 'away' ? c.awayId : 0,
            unders: c.choosen === 'under',
            overs: c.choosen === 'over',
            bodyOdd: c.bodyHandicap,
            goalOdd: c.goalHandicap,
            home: c.choosen === 'home',
            away: c.choosen === 'away',
            isHome: c.choosen === 'away' ? false : true,
            oppositeNameId: c.choosen === 'away' ? c.homeId : c.choosen === 'home' ? c.awayId : 0,
            isHomeBodyOdd: c.overId === c.homeId ? true : false,
            isFivePercentCom: confirmData.selectedMatches[0].isFivePercent,
          };
          a.push(obj);
          return a;
        }, [])
      };
      await bettingService
        .confirmBetting(payload)
        .then((response) => response.json());
      handleConfirmData(null);
      dispatch(setMixEvents([]));
      dispatch(setBodyEvents([]));
      dispatch(setEvents([]));
      openModal('Sport 909', 'လောင်းခြင်းအောင်မြင်ပါသည် ✓✓✓✓');
      handleConfirmData(null);
    } catch (e) {
      openModal('Sport 909', 'လောင်းခြင်းမအောင်မြင်ပါ!');
      console.log("Error :", e);
    } finally {
      setIsLoading(false);
      setIsButtonDisabled(false);
    }
  }

  const handleRemoveCard = (rapidEventId) => {
    const newData = confirmData?.selectedMatches.filter(x => x.rapidEventId !== rapidEventId);
    handleConfirmData({ betAmount: confirmData.betAmount, selectedMatches: newData, isMix: confirmData.isMix || false });
    getData();
  }

  const openModal = (title, content) => {
    setModalTitle(title);
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate(-1);
  };

  return (
    <div className="w-full h-screen flex flex-col dark:bg-bg_header_dark overflow-hidden" data-aos="fade-right">
      <div className="flex justify-start items-center py-2 dark:shadow-sm bg-bg_header dark:bg-bg_header_dark border-b-2 border-slate-100 dark:border-slate-700">
        <button
          onClick={goBack}
          className="top-3 left-5 w-10 h-10
                    rounded-full drop-shadow-lg flex justify-center items-center text-blue-700 text-4xl"
        >
          {/* <img src={backArrow} alt="Back Arrow" /> */}
          <IoIosArrowBack size={25} className="text-white" />
        </button>
        <div className="flex-1 justify-center items-center">
          <h3 className="text-white mr-7 text-center">{t('confirmBet')}</h3>
        </div>
      </div>
      <div className="h-screen overflow-auto">
        <div className="mx-2 mt-2 mb-1 flex space-x-2 text-base">
          <div className="w-1/5 border-2 border-black/[0.2] dark:border-border_confirmBet_dark/[0.2] rounded-[7px] p-2 text-center text-football font-normal dark:text-white text-sm">
            {t('no')}
          </div>
          <div className="w-2/5 border-2 border-black/[0.2] dark:border-border_confirmBet_dark/[0.2] rounded-[7px] p-2 text-center text-football font-normal dark:text-white text-sm">
            {t('events')}
          </div>
          <div className="w-2/5 border-2 border-black/[0.2] dark:border-border_confirmBet_dark/[0.2] rounded-[7px] p-2 text-center text-football font-normal dark:text-white text-sm">
            {t('choice')}
          </div>
        </div>
        <>
          {confirmData?.selectedMatches?.map((data, ind) => {
            return (
              <Card
                key={`${ind}`}
                number={ind + 1}
                team1={data.homeTeam}
                team2={data.awayTeam}
                normalColor={oddList && oddList.length > 0 ? oddList.includes(data.eventId) : true}
                odd={
                  data.choosen === 'over' || data.choosen === 'under'
                    ? data.goalHandicap
                    : data.bodyHandicap
                }
                choosen={data.choosen}
                match={data}
                handleRemoveCard={handleRemoveCard}
              />
            );
          })}
        </>
      </div>
      <div className="mt-auto flex flex-col p-2 space-y-2 text-sm border-t-2 border-slate-100 dark:border-slate-700">
        <div className="flex space-x-1">
          <div className="flex items-center">
            <div className="w-6 h-6 bg-football_text/[0.4] dark:bg-bg_bet/[0.55] border-2 border-bg_header_dark rounded-md mr-2 mb-1"></div>
            <p className="whitespace-nowrap text-xs dark:text-white">အရောင်ပြသထားသောပွဲစဥ်လောင်းကြေးပြောင်းလဲသည်</p>
          </div>
        </div>
        <div className="flex space-x-2">
          <div className="w-3/5 border-2 border-black/[0.2] dark:border-border_confirmBet_dark/[0.2] rounded-[7px] p-2 text-center text-football dark:text-white font-400">
            {t('betAmount')}
          </div>
          <div className="w-2/5 border-2 border-black/[0.2] dark:border-border_confirmBet_dark/[0.2] rounded-[7px] p-2 text-center text-football dark:text-white font-400 font-dangrek">
            {confirmData?.betAmount}
          </div>
        </div>
        <div className="flex space-x-2">
          <div className="w-3/5 border-2 border-black/[0.2] dark:border-border_confirmBet_dark/[0.2] rounded-[7px] p-2 text-center text-football dark:text-white font-400">
            {t('estimated')}
          </div>
          <div className="w-2/5 border-2 border-black/[0.2] dark:border-border_confirmBet_dark/[0.2] rounded-[7px] p-2 text-center text-football dark:text-white font-400 font-dangrek">
            {predictAmount}
          </div>
        </div>
        <button
          onClick={confirmBetting}
          className="bg-content border-[1px] border-border_rule_btn px-2 py-3 text-white font-dangrek font-500 rounded-[9px]"
          disabled={isButtonDisabled}
        >
          CONFIRM
        </button>
      </div>
      {isModalOpen && (
        <Modal title={modalTitle} content={modalContent} onClose={closeModal} />
      )}
    </div>
  );
};

export default ConfirmBet;
