import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import bettingService from "../../../api/services/bettingService";
import Song from "../../../assets/sound/newSong.mp3";

const initialState = {
  soundOn: localStorage.getItem("soundOn") === "false" ? "false" : "true",
  events: [],
  mixEvents: [],
  bodyEvents: [],
  bodySelectedLeagues: [],
  mixSelectedLeagues: [],
  selectedLeagues: [],
  confirmData: localStorage.getItem("confirmData")
    ? JSON.parse(localStorage.getItem("confirmData"))
    : localStorage.getItem("confirmData"),
  isLoading: false,
  oldHistoryDate: new Date().toISOString(),
  oldHistoryDateOption: 'choose',
  allLeaguesSelected: true,
};

const clickSong = new Audio(Song);

export const getMixEventAsyncAction = createAsyncThunk(
  'common/eventMix',
  async (payload, { getState }) => {
    const { userDetails } = getState().login;
    if (!userDetails) {
      return null; // You can return an appropriate value or null
    }
    const res = await bettingService
      .todayEvent({
        userId: userDetails.userId,
        isMix: true,
      })
      .then((response) => response.json());
    return res;
  }
);

export const getBodyEventAsyncAction = createAsyncThunk(
  'common/eventBody',
  async (payload, { getState }) => {
    const { userDetails } = getState().login;
    if (!userDetails) {
      return null; // You can return an appropriate value or null
    }
    const res = await bettingService
      .todayEvent({
        userId: userDetails.userId,
        isMix: false,
      })
      .then((response) => response.json());
    return res;
  }
);

export const commonAction = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSound: (state) => {
      state.soundOn = state.soundOn === "true" ? "false" : "true";
      localStorage.setItem(
        "soundOn",
        state.soundOn === "true" ? "false" : "true"
      );
    },
    playSong: (state) => {
      if (state.soundOn === "true") {
        clickSong.preload = "auto";
        clickSong.currentTime = 0;
        clickSong?.play();
      }
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setMixEvents: (state, action) => {
      state.mixEvents = action.payload;
    },
    setBodyEvents: (state, action) => {
      state.bodyEvents = action.payload;
    },
    setBodySelectedLeagues: (state, action) => {
      state.bodySelectedLeagues = action.payload;
    },
    setSelectedLeagues: (state, action) => {
      state.selectedLeagues = action.payload;
    },
    setMixSelectedLeagues: (state, action) => {
      state.mixSelectedLeagues = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setOldHistoryDate: (state, action) => {
      state.oldHistoryDate = action.payload;
    },
    setOldHistoryDateOption: (state, action) => {
      state.oldHistoryDateOption = action.payload;
    },
    setConfirmData: (state, action) => {
      state.confirmData = action.payload;
      if (action.payload) {
        localStorage.setItem("confirmData", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("confirmData");
      }
    },
    clearFilters: (state) => {
      state.bodySelectedLeagues = [];
      state.mixSelectedLeagues = [];
      state.selectedLeagues = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBodyEventAsyncAction.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.bodyEvents = action.payload;
        }
      });
    builder
      .addCase(getMixEventAsyncAction.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.mixEvents = action.payload;
        }
      });
  },
});


export const { playSong, setSound, setEvents, setBodySelectedLeagues, setSelectedLeagues, setMixEvents, setBodyEvents,
  setMixSelectedLeagues, setOldHistoryDate, setOldHistoryDateOption, setConfirmData, clearFilters } = commonAction.actions;
export const selectCommon = (state) => state.common;

export default commonAction.reducer;
