import React from "react";

const NotFoundPage = () => {
    return (
        <div className="inset-0 absolute flex bg-stone-800 justify-center items-center rounded text-white px-10 ">
            <h1>404 - Page Not Found</h1>
        </div>
    );
};

export default NotFoundPage;
