import React, { useEffect } from "react";
import { useState } from "react";
import ConfirmBet from "../confirmBet";
import Selection from "../selection";
import { useLocation } from "react-router-dom";
import { TbPlayFootball } from "react-icons/tb"
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import bettingService from "../../../api/services/bettingService";
import { playSong } from "../../common/actions/common.actions";
import { useDispatch } from "react-redux";
import Modal from "../../common/components/modal";
import { useSelector } from 'react-redux';
import { selectLoginData } from "../login/login.actions";
import { setConfirmData } from '../../common/actions/common.actions';
import dashboardService from "../../../api/services/dashboardService";

const Footer = ({ selectedMatches, fromBody = false, showToastMessage, handleRemoveCard }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showSelectedMatches, setShowSelectedMatches] = useState(false);
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [betAmount, setBetAmount] = useState('');
  const [maxBetAmount, setMaxBetAmount] = useState(0);
  const [minBetAmount, setMinBetAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleConfirmData = (data) => {
    dispatch(setConfirmData(data));
  };
  const { userDetails } = useSelector(selectLoginData);
  const { confirmData } = useSelector((state) => state.common);

  useEffect(() => {
    if (userDetails) {
      const userDetail = userDetails;
      setBalance(userDetail.balance);
      if (fromBody) {
        setMaxBetAmount(userDetail.betLimitForSingle);
      } else {
        setMaxBetAmount(userDetail.betLimitForMix);
      }
      if (confirmData) {
        setBetAmount(confirmData.betAmount || 0);
      }
    }
  }, [fromBody, confirmData]);

  const openModal = (title, content) => {
    setModalTitle(title);
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // navigate(-1);
  };

  const validation = () => {
    if (!selectedMatches || (selectedMatches && selectedMatches.length < 1)) {
      return "Please choose at least 1 match!";
    } else if (betAmount && betAmount > balance) {
      return `Bet amount should be less than your balance: ${balance}!`;
    } else if (betAmount && betAmount > maxBetAmount) {
      return `Bet amount should be less than ${maxBetAmount}!`;
    } else if (location.pathname === '/maungg') {
      if (selectedMatches && selectedMatches.length < 2) {
        return "Please choose at least 2 matches!";
      }
    }
  };

  const fetchLock = async () => {
    try {
      const userId = Number(userDetails.userId);
      const response = await dashboardService.getBalanceForDashboard(userId).then((response) => response.json());
      if (response.userInfo) {
        const updatedUserDetails = response.userInfo[0];
        if (updatedUserDetails.lock) {
          localStorage.removeItem('token');
          localStorage.removeItem('userDetails');
          localStorage.removeItem('confirmData');
          navigate("/login");
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchLock();
  }, []);


  const handleConfirm = async () => {
    try {
      fetchLock();
      const response = await bettingService.getMinMax();
      if (!response.ok) {
        throw new Error("Failed to fetch min/max bet amounts.");
      }
      const data = await response.json();
      const gamblingTypeInfo = data.find(typeInfo => typeInfo.gamblingTypeId === (fromBody ? 1 : 2));
      if (gamblingTypeInfo) {
        setMinBetAmount(gamblingTypeInfo.minBetAmount);
      }
      const error = validation(gamblingTypeInfo);
      if (error) {
        openModal('Sport 909', error);
        return;
      }

      if (betAmount < gamblingTypeInfo.minBetAmount) {
        openModal('Sport 909', `Bet amount should be greater than ${gamblingTypeInfo.minBetAmount}!`);
        return;
      }
      handleConfirmData({ betAmount, selectedMatches, isMix: location.pathname === '/maungg' ? true : false });
      handleSongClick();
      navigate("/confirm-bet");
    } catch (error) {
      console.error("Error fetching min/max bet amounts:", error);
    }
  };

  const handleClose = () => {
    console.log("closed!");
  };

  return (
    <div className="h-20 bg-bg_header dark:bg-bg_header_dark flex items-center justify-between px-2 border-t border-slate-400">
      {isModalOpen && (
        <Modal title={modalTitle} content={modalContent} onClose={closeModal} />
      )}
      <label className="text-white whitespace-nowrap px-1 mx-[1px]">{t('betAmount')}</label>
      <input
        type="number"
        inputMode='numeric'
        value={betAmount}
        onChange={(e) => {
          e.target.value !== '' && e.target.value !== '0' ?
          setBetAmount((oldBetAmt)=> {
            return Math.floor(e.target.value) > maxBetAmount || Math.floor(e.target.value) > balance ? oldBetAmt : Math.floor(e.target.value)
          })
          : setBetAmount('');
        }}
        className="w-1/2 h-10 mx-[2px] pr-1 border rounded-lg focus:outline-none focus:border-white text-white bg-gray-600 text-right"
      />
      <button className=" z-10" onClick={handleConfirm}>
        <div className="cursor-pointer text-white border p-2 mx-[2px] rounded-lg whitespace-nowrap">
          {t('bet')}
        </div>
      </button>
      {location.pathname === '/maungg' && (
        <div className="flex text-white border py-2 px-1 w-36 mx-[1px] rounded-lg justify-center items-center"
        // onClick={handleToggle}
        >
          <span>{selectedMatches.length}</span>
          <TbPlayFootball className="ml-[2px]" color="#fff" size={22} />
        </div>
      )}
      {/* <div
        className={`fixed top-0 left-0 w-full h-screen bg-white z-50 transform transition-transform ${showSelectedMatches ? 'translate-x-0' : '-translate-x-full'
          }`}
      >
        <Selection
          onClose={() => {
            setShowSelectedMatches(false);
            handleSongClick();
          }}
          selectedMatches={selectedMatches}
          betAmount={betAmount}
          handleRemoveCard={handleRemoveCard}
          handleConfirm={handleConfirm}
        />
      </div> */}
      <div
        className={`fixed top-0 left-0 w-full h-screen bg-white z-50 transform transition-transform ${showConfirmScreen ? 'translate-x-0' : '-translate-x-full'
          }`}
      >
        {/* <ConfirmBet onClose={handleClose} handleRemoveCard={handleRemoveCard} /> */}
      </div>
    </div>
  );
};

export default Footer;
