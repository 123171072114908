import React from "react";
import { useTranslation } from "react-i18next";
import * as moment from 'moment';

const Card = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className='flex flex-col font-dangrek text-md px-2 py-5 m-5 rounded-lg h-max dark:bg-white/[0.15] dark:border dark:border-white/[0.5] dark:text-white shadow-custom text-sm'>
            <div className='flex justify-end pb-2 mr-4'>
                <p className="text-content">
                    {
                        moment(new Date(data?.date)).format('DD.MM.YYYY h:mm A')
                    }
                </p>
            </div>
            <div className='grid grid-cols-2 gap-16 pl-6 py-1 pt-2'>
                <h4 className="whitespace-nowrap">{t("openingBalance")}</h4>
                <p className="font-myanmarKhay">{data?.opening}</p>
            </div>
            <div className='grid grid-cols-2 gap-16 pl-6 py-2'>
                <h4>{t("inward")}</h4>
                <p className="font-myanmarKhay">{data?.inward}</p>
            </div>
            <div className='grid grid-cols-2 gap-16 pl-6 py-2'>
                <h4>{t("betAmount")}</h4>
                <p className="font-myanmarKhay">{data?.bet}</p>
            </div>
            <div className='grid grid-cols-2 gap-16 pl-6 py-2'>
                <h4>{t("profitAmount")}</h4>
                <p className="font-myanmarKhay">{data?.win}</p>
            </div>
            <div className='grid grid-cols-2 gap-16 pl-6 py-2'>
                <h4>{t("outward")}</h4>
                <p className="font-myanmarKhay">{data?.outward}</p>
            </div>
            <div className='grid grid-cols-2 gap-16 pl-6 py-1 pb-2'>
                <h4 className="whitespace-nowrap">{t("closingBalance")}</h4>
                <p className="font-myanmarKhay font-semibold">{data?.closing}</p>
            </div>
        </div>
    );
}

export default Card;
