import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { setSound, playSong, selectCommon } from '../../common/actions/common.actions';
// import GearIcon from "../../../assets/icons/Gear.svg";
// import TranslateIcon from "../../../assets/icons/Google Translate.svg";
// import WhiteTranslate from "../../../assets/icons/whiteTranslate.svg";
// import LockIcon from "../../../assets/icons/Secure.svg";
// import SpeakerIcon from "../../../assets/icons/Speaker.svg";
// import WhiteSpeaker from "../../../assets/icons/whiteSpeaker.svg";
// import WhiteGear from "../../../assets/icons/whiteGear.svg";
// import WhiteLock from "../../../assets/icons/whiteSecure.svg";
import { Link, useNavigate } from "react-router-dom";
// import forwardArrow from "../../../assets/icons/left-arrow.png";
// import backArrow from "../../../assets/icons/Back.svg";
import { IoIosArrowBack } from "react-icons/io";
import { LiaLanguageSolid } from "react-icons/lia";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiFillSound } from "react-icons/ai";
import { BsGear } from "react-icons/bs";

const Setting = () => {
  const dispatch = useDispatch();
  const { soundOn } = useSelector(selectCommon)
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  // add akn for click song
  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleCardClick = () => {
    dispatch(playSong());
    dispatch(setSound());
  };

  const onChangeLanguage = () => {
    handleSongClick();
    i18n.changeLanguage(i18n.language === "en" ? "mm" : "en");
    localStorage.setItem("language", i18n.language);
  };

  const handleBackClick = () => {
    navigate("/dashboard");
  };

  const handleChangePasswordClick = (e) => {
    handleSongClick();
    e.preventDefault();
    navigate("/change-password")
  }

  return (
    <div className="w-full flex flex-col font-dangrek h-screen overflow-hidden dark:bg-black dark:text-white">
      <div className="flex justify-start items-center py-3 border-b border-border_dark pl-2 pr-5 dark-bg-bg_header_dark">
        <button
          className="pt-1 top-10 left-2 w-10 h-10 mr-3 flex text-white text-4xl"
          onClick={handleBackClick}
        >
          {/* <img src={forwardArrow} className="dark:hidden mt-2 w-5 h-5" alt="Back Arrow" />
          <img src={backArrow} className="hidden dark:block w-7 h-8" alt="Back Arrow" /> */}
          <IoIosArrowBack size={28} className="text-black dark:text-white mt-1" />
        </button>
        {/* <span className="dark:hidden">
          <img src={GearIcon} alt="Gear"></img>
        </span>
        <span className="hidden dark:block">
          <img src={WhiteGear} alt="White Gear"></img>
        </span> */}
        <BsGear size={23} />
        <h3 className="pl-3 text-xl">Setting</h3>
      </div>
      <div className="flex flex-1 flex-col p-4 mt-3 overflow-auto">
        <div
          onClick={onChangeLanguage}
          className=" cursor-pointer h-max p-5 shadow-custom rounded grid grid-cols-6 mb-3 dark:border dark:border-border_rule_btn/[0.25] dark:bg-white/[0.15]"
        >
          {/* <span className="dark:hidden">
            <img src={TranslateIcon} alt="Translate" />
          </span>
          <span className="hidden dark:block">
            <img src={WhiteTranslate} alt="White Translate"></img>
          </span> */}
          <LiaLanguageSolid size={26} />
          <h3 className="col-span-4 flex items-center whitespace-nowrap">{t('language')}</h3>
        </div>
        <Link className="z-40 w-full" onClick={(e) => handleChangePasswordClick(e)}>
          <div className="h-max p-5 shadow-custom rounded grid grid-cols-6 mb-3 dark:border dark:border-border_rule_btn/[0.25] dark:bg-white/[0.15]">
            {/* <span className="dark:hidden">
              <img src={LockIcon} alt="Lock" />
            </span>
            <span className="hidden dark:block">
              <img src={WhiteLock} alt="white lock"></img>
            </span> */}
            <RiLockPasswordFill size={24} />
            <h3 className="col-span-4 flex items-center whitespace-nowrap">
              {t('changePassword')}
            </h3>
          </div>
        </Link>
        <div
          className="h-max p-5 cursor-pointer shadow-custom rounded grid grid-cols-6 mb-3 dark:border dark:border-border_rule_btn/[0.25] dark:bg-white/[0.15]"
          onClick={handleCardClick}
        >
          {/* <span className="dark:hidden">
            <img src={SpeakerIcon} alt="Icon" />
          </span>
          <span className="hidden dark:block">
            <img src={WhiteSpeaker} alt="White speaker"></img>
          </span> */}
          <AiFillSound size={26} />
          <h3 className="col-span-4 flex items-center whitespace-nowrap">Betting Click Song</h3>
          {soundOn === "true" && (
            <span className="col-span-1 text-content flex items-center justify-end">On</span>
          )}
        </div>
      </div>
      <div className="h-10 flex justify-end pr-8 text-xs text-gray-400">
        <p>Version - 2.0.1.8</p>
      </div>
    </div>
  );
};

export default Setting;
