import { getMixEventAsyncAction } from '../features/common/actions/common.actions';
import { getBodyEventAsyncAction } from '../features/common/actions/common.actions';

export function eventDataFetchInterval(dispatch, path) {

  const fetchData = () => {
    if (path === '/body') {
      dispatch(getBodyEventAsyncAction());
    } else {
      dispatch(getMixEventAsyncAction());
    }
  };

  // Fetch data immediately
  // fetchData();

  const intervalId = setInterval(fetchData, 30000);

  // Return a function to stop the interval (cleanup)
  return () => {
    clearInterval(intervalId);
  };
}
