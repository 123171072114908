import React, { useState, useEffect, useMemo, useCallback } from "react";
import FilterView from "../filter";
import Sort from "../sort";
import Content from "../body/content";
import Footer from "../body/footer";
import { useNavigate } from "react-router-dom";
// import bettingService from "../../../api/services/bettingService";
import { useTranslation } from "react-i18next";
import { setEvents, setBodySelectedLeagues, getBodyEventAsyncAction } from '../../common/actions/common.actions';
import { useSelector, useDispatch } from 'react-redux';
import { playSong } from "../../common/actions/common.actions";
// import clear from "../../../assets/icons/clear.png";
import { selectLoginData } from "../login/login.actions";
import { IoIosArrowBack } from "react-icons/io";
import { FiFilter, FiSearch } from "react-icons/fi";
import { LiaSortAmountDownSolid } from "react-icons/lia";
// import { LuRefreshCcw } from "react-icons/lu";
import { setConfirmData } from '../../common/actions/common.actions';
import { MdCancel } from "react-icons/md";
import { BiFootball } from 'react-icons/bi';

const Body = ({ isLoading, setIsLoading, showToastMessage }) => {
  const navigate = useNavigate();
  const { events, bodySelectedLeagues, confirmData, bodyEvents } = useSelector((state) => state.common);
  const { userDetails } = useSelector(selectLoginData);
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);
  const [leagues, setLeagues] = useState([]);
  const [selectedMatches, setSelectedMatches] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [showSorting, setShowSorting] = useState(false);
  const [sort, setSort] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [userData, setUserData] = useState(userDetails);
  const [deselectAll, setDeselectAll] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setUserData(() => userDetails)
    if (confirmData) {
      dispatch(setConfirmData(confirmData));
      setSelectedMatches(confirmData.selectedMatches);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const getEvent = useCallback(async () => {
    try {
      const res = bodyEvents;
      if (res?.islock) {
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        navigate("/login");
      }
      if (!bodyEvents || !bodyEvents.leagueList || bodyEvents.leagueList.length === 0) {
        return;
      }
      const oldLeague = Array.from(leagues);
      const eventData = [];
      res.eventDetails.forEach((item) => {
        item.eventList.forEach((eventItem) => {
          const eventKeys = Object.keys(eventItem);
          const event = {};
          eventKeys.forEach((key) => {
            event[key] = eventItem[key];
          });

          eventData.push({
            ...event,
            leagueName: item.league,
            leagueId: item.leagueId,
          });
        });
      });

      const sortedMatches = eventData?.sort((a, b) => {
        return new Date(a.eventTime) - new Date(b.eventTime);
      });
      dispatch(setEvents(sortedMatches));

      const selection = bodySelectedLeagues;
      if (selection && selection !== null && selection.length > 0) {
        const newSelection = [...selection];
        if (bodyEvents && bodyEvents.leagueList && bodyEvents.leagueList.length > 0 && oldLeague.length !== 0) {
          const oldLeagueNames = new Set(oldLeague.map(item => item.leagueName));
          const newLeagues = bodyEvents.leagueList.filter(item => !oldLeagueNames.has(item.leagueName));
          if (newLeagues && newLeagues.length > 0) {
            const newLeagueNames = newLeagues.map(c => c.leagueName);
            newSelection.push(...newLeagueNames);
          }
        }
        dispatch(setBodySelectedLeagues(newSelection));
      } else {
        if (bodySelectedLeagues && bodySelectedLeagues.length === 0 && bodyEvents && bodyEvents.leagueList && bodyEvents.leagueList.length > 0 && !deselectAll) {
          dispatch(setBodySelectedLeagues(bodyEvents.leagueList.map((league) => league.leagueName)));
        }
      }
    } catch (error) {
      console.error("API request error:", error);
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ bodyEvents ]);

  useEffect(() => {
    const sortedLeagues = bodyEvents && bodyEvents.length !== 0 ? [...bodyEvents.leagueList].sort((a, b) => {
      return a.leagueName.localeCompare(b.leagueName);
    }) : [];
    setLeagues(()=>sortedLeagues);
  }, [bodyEvents]);

  useEffect(() => {
    const validEventIDs = events.map((x) => x.eventId);
    if (confirmData && confirmData.selectedMatches && confirmData.selectedMatches.length > 0) {
      const validConfirmData = confirmData.selectedMatches.filter(x => validEventIDs.includes(x.eventId));
      dispatch(setConfirmData({ betAmount: confirmData.betAmount, selectedMatches: validConfirmData }));
    }
    if (selectedMatches && selectedMatches.length > 0) {
      const validSelectedMatches = selectedMatches.filter(x => validEventIDs.includes(x.eventId));
      setSelectedMatches(validSelectedMatches);
    }
  }, [events]);

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  useEffect(() => {
    const getFetchData = async () => {
      if (userData) {
        setIsLoading(true);
        await dispatch(getBodyEventAsyncAction());
      }
    }
    getFetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectTeam = (choosen, match, maxTeam = 1, fromBody = true) => {
    const ind =
      selectedMatches && selectedMatches.length > 0
        ? selectedMatches.findIndex((x) => x.eventId === match.eventId)
        : -1;
    const isSameItem =
      (selectedMatches && selectedMatches.length > 0
        ? selectedMatches.findIndex((x) => x.eventId === match.eventId && x.choosen === choosen)
        : -1) >= 0;
    if (!isSameItem && (!selectedMatches || selectedMatches.length < maxTeam)) {
      if (selectedMatches && selectedMatches.length > 0) {
        setSelectedMatches([
          ...(ind >= 0
            ? selectedMatches.filter((x) => x.eventId !== match.eventId)
            : selectedMatches),
          { choosen, ...match },
        ]);
      } else {
        setSelectedMatches([{ choosen, ...match }]);
      }
    } else {
      if (fromBody && !isSameItem) {
        setSelectedMatches([{ choosen, ...match }]);
      } else {
        const tmpData = selectedMatches.filter((x) => x.eventId !== match.eventId);
        setSelectedMatches(tmpData);
      }
    }
  };

  const filteredMatches = useMemo(() => {
    const sortedEvents = [...events];

    const fivePercentEvents = sortedEvents.filter(event => event.isFivePercent);
    const otherEvents = sortedEvents.filter(event => !event.isFivePercent);



    if (sort === null || sort === "eventTime") {
      fivePercentEvents.sort((a, b) => new Date(a.eventTime) - new Date(b.eventTime));

      otherEvents.sort((a, b) => new Date(a.eventTime) - new Date(b.eventTime));

    } else {
      fivePercentEvents.sort((a, b) => a[sort].localeCompare(b[sort]));

      otherEvents.sort((a, b) => a[sort].localeCompare(b[sort]));
    }

    const combinedSortedMatches = [...fivePercentEvents, ...otherEvents];
    // Filter events based on the search input
    const filtered = searchInput
      ? combinedSortedMatches.filter((event) => {
        const searchTerm = searchInput?.toLowerCase();
        return (
          event.leagueName.toLowerCase().includes(searchTerm) ||
          event.homeTeam.toLowerCase().includes(searchTerm) ||
          event.homeTeamMyan.toLowerCase().includes(searchTerm) ||
          event.awayTeam.toLowerCase().includes(searchTerm) ||
          event.awayTeamMyan.toLowerCase().includes(searchTerm)
        );
      })
      : combinedSortedMatches;

    let sortedMatches = filtered;
    if (sort !== null) {
      if (sort === "leagueName") {
        sortedMatches = [...filtered].sort((a, b) => {
          if (a.isFivePercent && !b.isFivePercent) {
            return -1;
          } else if (!a.isFivePercent && b.isFivePercent) {
            return 1;
          } else {
            return a.leagueName.localeCompare(b.leagueName);
          }
        });
      }
    }

    return sortedMatches;

    // if (sort !== null) {
    //   console.log('sort in body', sort);

    //   // eslint-disable-next-line array-callback-return
    //   const sortedMatches = filtered.sort((a, b) => {
    //     if (sort === 'leagueName') {
    //       return a[sort].localeCompare(b[sort]);
    //     } else if (sort === 'eventTime') {
    //       return new Date(a.eventTime) - new Date(b.eventTime);
    //     }
    //   });
    //   dispatch(setEvents(sortedMatches));
    //   // handleSetEvents(sortedMatches);
    // }
    // console.log('filtered =>', filtered);
    // return filtered;
  }, [events, searchInput, sort]);

  const handleFilterClick = async () => {
    await handleSongClick();
    setShowFilter(!showFilter);
  };

  const handleFilterApply = () => {
    setShowFilter(false);
  };

  const handleLeagueSelection = (bodySelectedLeagues) => {
    setDeselectAll(bodySelectedLeagues.length === 0);
    dispatch(setBodySelectedLeagues(bodySelectedLeagues));
  };

  const handleSortingSelection = useCallback((selectedSort) => {
    setSort(selectedSort);
  }, []);

  const handleSortingClick = async () => {
    await handleSongClick();
    setShowSorting(!showSorting);
  };

  const handleSortingApply = () => {
    setShowSorting(false);
  };

  const handleSearchClick = async () => {
    await handleSongClick();
    setShowSearch(!showSearch);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleBackClick = () => {
    handleSongClick();
    navigate('/dashboard');
  };

  const handleClearSearch = () => {
    handleSongClick();
    setSearchInput(''); // Clear the search input
    setShowSearch(false);
  };

  const isWideWindow = window.innerWidth >= 1024;

  return (
    <div className="w-full h-screen flex flex-col overflow-hidden relative bg-white dark:bg-bg_header_dark">
      <div>
        <div className="w-full py-2 flex justify-between items-center bg-bg_header dark:bg-bg_header_dark border-b border-slate-400">
          <button
            className="top-3 left-3 w-10 h-10 mr-5 ml-3 flex justify-center items-center text-white text-4xl"
            onClick={handleBackClick}
          >
            <IoIosArrowBack size={25} />
          </button>
          {showSearch ? (
            ''
          ) : (
            <div className="w-full">
              <h3 className="text-white">{t('bodyGoal')}</h3>
            </div>
          )}
          {showSearch ? (
            <div className="w-full relative flex items-center">
              <input
                type="text"
                className="border font-breeSerif bg-search w-full p-2 pl-10 focus:outline-none focus:ring-0 rounded-3xl"
                placeholder="Search"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              <FiSearch size={18} className="absolute left-4" />
              <button
                className="absolute right-4"
                onClick={handleClearSearch}
              >
                {/* <img src={clear} alt="Clear" className="w-5 h-5" /> */}
                <MdCancel size={21} className="text-gray-400" />
              </button>
            </div>
          ) : (
            ''
          )}
          <div className="flex justify-between pr-1">
            {showSearch ? (
              ''
            ) : (
              <button onClick={handleSortingClick} className='text-white px-2'>
                <LiaSortAmountDownSolid size={22} />
              </button>
            )}
            {
              showSearch ? (
                ''
              ) : (
                <button onClick={handleSearchClick} className='text-white px-2'>
                  <FiSearch size={21} />
                </button>
              )
            }
            {showSearch ? (
              ''
            ) : (
              <button onClick={handleFilterClick} className='text-white px-2'>
                <FiFilter size={20} />
              </button>
            )}
            {/* {showSearch ? (
              ''
            ) : (
              <div className="text-white px-2" onClick={handleRefreshClick}>
                <LuRefreshCcw size={21} className={`${isLoading ? 'animate-spin' : ''}`} />
              </div>
            )} */}
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-auto">
        <ul className="w-full flex flex-col items-center scroll-smooth list-none p-0" data-aos="fade-up">
          {/* Scrollable content */}
          {filteredMatches.map((match, index) => {
            console.log(filteredMatches);
            return bodySelectedLeagues?.map((league) => {
              if (league === match.leagueName) {
                return (
                  <>
                    <Content
                      key={index}
                      match={match}
                      sendDataToParent={handleSelectTeam}
                      selectedMatches={selectedMatches}
                      eventTime={match.eventTime}
                      homeTeam={i18n.language === 'en' ? match.homeTeam : match.homeTeamMyan}
                      awayTeam={i18n.language === 'en' ? match.awayTeam : match.awayTeamMyan}
                      bodyHandicap={match.bodyHandicap}
                      goalHandicap={match.goalHandicap}
                      isFivePercent={match?.isFivePercent}
                    />
                    {/* <div className="flex gap-10 m-2">
                      <BiFootball size={15} className="text-k_groupBgColorEight dark:text-white" />
                      <BiFootball size={15} className="text-k_groupBgColorEight dark:text-white" />
                      <BiFootball size={15} className="text-k_groupBgColorEight dark:text-white" />
                      <BiFootball size={15} className="text-k_groupBgColorEight dark:text-white" />
                      <BiFootball size={15} className="text-k_groupBgColorEight dark:text-white" />
                    </div> */}
                  </>

                );
              }
              return null;
            });
          })}
        </ul>
      </div>
      <div>
        <Footer
          selectedMatches={selectedMatches}
          fromBody={true}
          showToastMessage={showToastMessage}
        />
      </div>
      <div>
        <div
          className={`absolute top-12 rounded dark:border-none border-2 dark:text-white right-2 bg-white p-4 dark:bg-bg_header_dark shadow-lg font-dangrek ${showSorting ? 'block' : 'hidden'
            }`}
        >
          <Sort onClose={handleSortingApply} onSortingSelection={handleSortingSelection} />
        </div>
      </div>
      <div
        className={`fixed top-0 left-0 w-full ${isWideWindow ? 'w-[85%] left-60' : ''} h-screen bg-white z-50 transform transition-transform ${showFilter ? 'translate-y-0' : '-translate-y-full'
          }`}
      >
        <FilterView
          onClose={handleFilterApply}
          leagues={leagues}
          onLeagueSelection={handleLeagueSelection}
          events={events.length}
          mixBody="body"
        />
      </div>
    </div>
  );
};

export default Body;
