import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { IoIosArrowBack } from "react-icons/io";
import SelectedMatchesCard from "./selectedMatchesCard";
// import HumburgerMenu from "../../../assets/icons/humburger.svg";
import bettingService from "../../../api/services/bettingService";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { playSong } from '../../common/actions/common.actions';
import { setSelectedMatchesDetail } from '../../common/components/sidebar/sidebar.actions';
import { GiHamburgerMenu } from "react-icons/gi";

const SelectedMatches = ({ setIsLoading }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedMatches, setSelectedMatches] = useState([]);
  // const [selectedMatchesWithDetail, setSelectedMatchesWithDetail] = useState([]);
  const [filter, setFilter] = useState("အားလုံး");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleBackClick = () => {
    handleSongClick();
    navigate('/dashboard');
  };

  const handleHamburgerClick = () => {
    handleSongClick();
    setShowOptions(!showOptions); // Toggle options visibility
  };

  const handleOptionClick = (option) => {
    setFilter(option);
    setShowOptions(false);
  }

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      bettingService
        .bettingMatchesHistory()
        .then((response) => response.json())
        .then((data) => {
          // Filter the data based on the selected filter
          if (data) {
            if (filter === "အားလုံး") {
              setSelectedMatches(data);
            } else if (filter === "ဘော်ဒီ") {
              const filteredMatches = data.filter(
                (match) => match.gamblingTypeId === 1
              );
              setSelectedMatches(filteredMatches);
            } else if (filter === "မောင်း") {
              const filteredMatches = data.filter(
                (match) => match.gamblingTypeId === 2
              );
              setSelectedMatches(filteredMatches);
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.error("API request error:", err);
        });
    };
    fetchData();
  }, [filter, navigate, setIsLoading]);

  const sortedMatches = [...selectedMatches].sort((a, b) => {
    const dateA = new Date(a.createdDate);
    const dateB = new Date(b.createdDate);
    return dateB - dateA;
  });
  // useEffect(() => {
  //   if (selectedMatches && selectedMatches.length > 0) {
  //     const fetchData = async () => {
  //       const detailData = await Promise.all(
  //         selectedMatches.map(async (match) => {
  //           try {
  //             const response = await bettingService.bettingHistoryDetails({
  //               gamblingId: Number(match.gamblingId),
  //               status: "Inplay",
  //             });
  //             const data = await response.json();
  //             if (data && data.length > 0) {
  //               return { ...match, ...data[0] };
  //             }
  //           } catch (err) {
  //             if (err?.response?.status === 401) {
  //               // Redirect to the login page
  //               navigate("/login");
  //             } else {
  //               console.error("API request error:", err);
  //             }
  //           }
  //         })
  //       );
  //       setSelectedMatchesWithDetail(detailData);
  //       setIsLoading(false);
  //     };

  //     fetchData();
  //   }
  // }, [selectedMatches, navigate, setIsLoading]);


  return (
    <div className="flex flex-col w-full h-screen bg-white/[0.2] dark:bg-bg_header_dark overflow-hidden">
      <div className="flex justify-between items-center py-2 pr-4 bg-bg_header dark:bg-bg_header_dark border-b dark:border-b dark:border-border_dark/[0.7]">
        <div
          className="flex justify-center items-center"
          onClick={handleBackClick}
        >
          <button
            className="top-3 left-3 w-10 h-10 mr-3
                    rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl"
          >
            <IoIosArrowBack size={25} />
          </button>
          <div>
            <h3 className="text-white">
              {t("selectedMatches")}
            </h3>
          </div>
        </div>
        <div className="relative">
          {/* <img
            src={HumburgerMenu}
            alt="Hamburger Menu"
            onClick={handleHamburgerClick}
          /> */}
          <div onClick={handleHamburgerClick}>
            <GiHamburgerMenu size={22} className="text-white" />
          </div>
          {showOptions && (
            <div className="absolute top-12 rounded border-2 dark:text-white right-0 bg-white p-4 dark:bg-bg_header_dark shadow-lg dark:border-2 dark:border-slate-800">
              <div
                className={`border-b border-black/[0.5] dark:border-white/[0.3] pb-2 pl-4 pr-14 ${filter === "အားလုံး" ? "font-bold" : ""
                  }`}
              >
                <p
                  className="cursor-pointer"
                  onClick={() => handleOptionClick("အားလုံး")}
                >
                  {t("all")}
                </p>
              </div>
              <div
                className={`border-b border-black/[0.5] dark:border-white/[0.3] py-2 pl-4 pr-14 ${filter === "မောင်း" ? "font-bold" : ""
                  }`}
              >
                <p
                  className="cursor-pointer"
                  onClick={() => handleOptionClick("မောင်း")}
                >
                  {t("maungg")}
                </p>
              </div>
              <div
                className={`py-2 pl-4 pr-14 ${filter === "ဘော်ဒီ" ? "font-bold" : ""
                  }`}
              >
                <p
                  className="cursor-pointer"
                  onClick={() => handleOptionClick("ဘော်ဒီ")}
                >
                  {t("body")}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex-1 overflow-auto">
        {sortedMatches?.map((data, index) => {
          if (data?.gamblingTypeId === 2) {
            return (
              <Link
                key={`${index}`}
                to={`/selected-matches-detail/${data?.gamblingId}`}
                onClick={() => {
                  dispatch(setSelectedMatchesDetail(data));
                  handleSongClick();
                }}
              >
                <SelectedMatchesCard match={data} />
              </Link>
            );
          } else {
            return <SelectedMatchesCard match={data} key={`${index}`} />;
          }
        })}
      </div>
    </div >
  );
}

export default SelectedMatches;
