import client from "../client";
import { apiList } from "../apiList";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // returns list of all the people
  getTransactionsForDashboard: (payload) => client.put(apiList.getTransactionsForDashboard, { json: payload }),
  getBalanceForDashboard: (userId) => client.get(`${apiList.getUserInfoAndCommission}${userId}`),
  getDisplayTextForDashboard: () => client.get(apiList.getDisplayText),
  getHotNews: () => client.get(apiList.hotNews),
};


// {userId: userId, startDate: startDate, endDate: endDate}