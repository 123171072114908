import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo';
import statementIcon from '../../../../assets/icons/price-tag.svg';
// import ruleIcon from '../../../../assets/icons/rules.svg';
// import settingIcon from '../../../../assets/icons/setting.svg';
// import logoutIcon from '../../../../assets/icons/logout.svg';
import { useTranslation } from 'react-i18next';
import LogoutHelper from '../../../../helpers/LogoutHelper';
// import right from '../../../../assets/icons/right.svg';
import upArrow from '../../../../assets/icons/uparrow.svg';
// import shoeIcon from '../../../../assets/icons/shoe.svg';
import { useDispatch } from 'react-redux';
import { clearFilters, playSong } from '../../actions/common.actions';
import { useSelector } from 'react-redux';
import { selectLoginData } from "../../../pages/login/login.actions";
import { FiHome } from 'react-icons/fi';
import { BiFootball } from 'react-icons/bi';
// import { PiCurrencyDollarSimpleBold } from "react-icons/pi";
import { GiRunningShoe } from "react-icons/gi";
// import { PiArrowLineUpRightBold } from "react-icons/pi";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { PiNewspaperClipping } from "react-icons/pi";
import { GoGear } from "react-icons/go";
import { RiLogoutBoxLine } from "react-icons/ri";

export const SideBarTwo = ({ setShowSideBar = false }) => {
  const { t } = useTranslation();
  const { userDetails } = useSelector(selectLoginData);
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  useEffect(() => {
    const handleClick = (e) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(e.target) &&
        !e.target.classList.contains('toggle')
      ) {
        setShowSideBar(false);
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  // useEffect(() => {
  //   const updateShowSidebar = () => {
  //     if (window.innerWidth < 640) {
  //       setShowSideBar(false);
  //       console.log('hide')
  //     } else {
  //       setShowSideBar(true);
  //       console.log('show');
  //     }
  //   };

  //   window.addEventListener('resize', updateShowSidebar);

  //   return () => {
  //     window.removeEventListener('resize', updateShowSidebar);
  //   };
  // }, []);

  const handleAccount = (e) => {
    // navigate(`/${e.target.id}`);
    handleSongClick();
    setShowSideBar(false);
  };

  // const handleLogOut = () => {
  //   localStorage.removeItem('token');
  //   setShowSideBar(false);
  // }
  return (
    <div ref={sidebarRef} className="w-full sm:w-[250px] absolute lg:bg-primary lg:static inset-0 h-screen duration-300 flex flex-col z-[1000]">
      <div
        ref={sidebarRef}
        className="w-[250px] sm:full flex flex-col h-screen absolute lg:static inset-0 z-[1000]"
      >
        <div className="h-full pb-4 pt-6 overflow-y-auto backdrop-blur-lg bg-gray-700/[0.4] dark:bg-gray-800/[0.4]">
          <div className="-z-10 origin-top-left rotate-[8.63deg] opacity-70 w-48 h-80 left-[38px] top-[300px] absolute">
            <div className="z-0 w-20 h-24 left-[49.90px] top-[20.66px] absolute origin-top-left rotate-[-1.34deg] bg-blue-500 rounded-full blur-3xl"></div>
            <div className="z-0 w-20 h-16 left-[4.37px] top-[88.22px] absolute origin-top-left rotate-[-1.34deg] bg-gray-700 blur-3xl"></div>
          </div>
          <div className=" -z-10 origin-top-left rotate-[35.28deg] opacity-70 w-30 h-80 left-[112.67px] top-0 absolute">
            <div className="z-0 w-20 h-24 left-[39.68px] top-[43.98px] absolute origin-top-left rotate-[25.32deg] bg-blue-500 rounded-full blur-3xl"></div>
            <div className="z-0 w-20 h-20 left-[-18.81px] top-[90.23px] absolute origin-top-left rotate-[25.32deg] bg-gray-700 blur-3xl"></div>
            <div className="z-0 w-16 h-28 left-[3.42px] top-[279.96px] absolute origin-top-left rotate-[35.28deg] bg-gray-700 blur-3xl"></div>
          </div>
          <Logo isSidebar={true} />
          <div className='w-full text-center text-sm text-white dark:text-betID'>{userDetails?.username}</div>

          <ul className="space-y-1 font-medium z-10 mt-8">
            <li>
              <Link
                to="/dashboard"
                onClick={handleAccount}
                className="flex items-center pl-5 pr-2 py-3 text-white dark:text-white hover:bg-primary/[0.4] dark:hover:bg-gray-700 group"
              >
                <FiHome size={26} />
                <span className="ml-3 text-white">HOME</span>
              </Link>
            </li>
            <li>
              <Link
                to="/account"
                onClick={handleAccount}
                className="flex items-center pl-5 pr-2 py-3 text-gray-900 dark:text-white hover:bg-primary/[0.4] dark:hover:bg-gray-700 group"
              >
                <img src={statementIcon} alt="statement" className="w-7 h-7" />
                {/* <PiCurrencyDollarSimpleBold size={26} className='text-white' /> */}
                <span className="ml-3 text-white">{t('statement')}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/maungg"
                onClick={handleAccount}
                className="flex items-center pl-5 pr-2 py-3 text-gray-900 dark:text-white hover:bg-primary/[0.4] dark:hover:bg-gray-700 group"
              >
                {/* <img src={shoeIcon} alt="shoeIcon" className="w-7 h-7" /> */}
                <GiRunningShoe size={26} className='text-white' />
                <span className="ml-3 text-white uppercase">{t('maungg')}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/body"
                onClick={handleAccount}
                className="flex items-center pl-5 pr-2 py-3 text-gray-900 dark:text-white hover:bg-primary/[0.4] dark:hover:bg-gray-700 group"
              >
                <BiFootball size={25} className='text-white' />
                <span className="ml-3 text-white uppercase">{t('bodyGoal')}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/selected-matches"
                onClick={handleAccount}
                className="flex items-center pl-5 pr-2 py-3 text-gray-900 dark:text-white hover:bg-primary/[0.4] dark:hover:bg-gray-700 group"
              >
                <img src={upArrow} alt="upArrow" className="w-7 h-7" />
                {/* <PiArrowLineUpRightBold size={26} className="text-white" /> */}
                <span className="ml-3 text-white uppercase">
                  {t('selected')}
                  {t('matches')}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/old-histories"
                onClick={handleAccount}
                className="flex items-center pl-5 pr-2 py-3 text-gray-900 dark:text-white hover:bg-primary/[0.4] dark:hover:bg-gray-700 group"
              >
                {/* <img src={right} alt="right" className="w-7 h-7" /> */}
                <IoCheckmarkDoneSharp size={26} className="text-white" />
                <span className="ml-3 text-white uppercase">{t('oldHistories')}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/rules"
                onClick={handleAccount}
                className="flex items-center pl-5 pr-2 py-3 text-gray-900 dark:text-white hover:bg-primary/[0.4] dark:hover:bg-gray-700 group"
              >
                {/* <img src={ruleIcon} alt="rule" className="w-7 h-7" /> */}
                <PiNewspaperClipping size={26} className="text-white" />
                <span className="ml-3 text-white uppercase">{t('rule')}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/setting"
                onClick={handleAccount}
                className="flex items-center pl-5 pr-2 py-3 text-gray-900 dark:text-white hover:bg-primary/[0.4] dark:hover:bg-gray-700 group"
              >
                {/* <img src={settingIcon} alt="setting" className="w-7 h-7" /> */}
                <GoGear size={26} className="text-white" />
                <span className="ml-3 text-white">SETTINGS</span>
              </Link>
            </li>
            <li>
              <Link
                to="/"
                // onClick={LogoutHelper}
                onClick={(e) => {
                  e.preventDefault();
                  setShowSideBar(false);
                  dispatch(clearFilters());
                  LogoutHelper();

                  // window.innerWidth >= 640 ? setShowSideBar(true): setShowSideBar(false);
                }}
                className="flex items-center pl-5 pr-2 py-3 text-gray-900 dark:text-white hover:bg-primary/[0.4] dark:hover:bg-gray-700 group"
              >
                {/* <img src={logoutIcon} alt="logout" className="w-7 h-7" /> */}
                <RiLogoutBoxLine size={26} className="text-white" />
                <span className="ml-3 text-white">LOGOUT</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div onClick={() => setShowSideBar(false)} className="w-full sm:w-0 h-screen absolute bg-primary/[0.1] z-[900] right-0" />
    </div>
  );
};
