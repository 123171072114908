const CryptoJS = require("crypto-js");
const secret = "eA$yp#e9u4e";

export const encrypt = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secret).toString();
};

export const decrypt = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secret);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
