import React from "react";
// import backArrow from "../../../assets/icons/Back.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import bettingService from "../../../api/services/bettingService";
import { useState } from "react";
import { useEffect } from "react";
import * as moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { playSong } from '../../common/actions/common.actions';
// import Share from '../../../assets/icons/share.png';
import { IoIosArrowBack } from "react-icons/io";

const SelectedMathesDetail = ({ setIsLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedMatchesDetail } = useSelector(state => state.sidebar);
    const { id } = useParams();
    const { t } = useTranslation();
    const [selectedMatches, setSelectedMatches] = useState([]);
    const language = localStorage.getItem("language");

    const handleSongClick = async () => {
        dispatch(playSong());
    };

    const handleBackClick = async () => {
        await handleSongClick();
        navigate("/selected-matches");
    };

    useEffect(() => {
        setIsLoading(true);
        bettingService.bettingHistoryDetails({
            "gamblingId": Number(id),
            "status": "Inplay"
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    const sortedData = data.slice().sort((a, b) => {
                        const dateA = new Date(
                            a.eventDate.split(' ')[0].split('-').reverse().join('-') + ' ' + a.eventDate.split(' ')[1]
                        );
                        const dateB = new Date(
                            b.eventDate.split(' ')[0].split('-').reverse().join('-') + ' ' + b.eventDate.split(' ')[1]
                        );
                        return dateA - dateB;
                    });
                    setSelectedMatches(sortedData)
                }
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                if (err.response.status === 401) {
                    // Redirect to the login page
                    navigate("/login");
                } else {
                    console.error("API request error:", err);
                }
            });
    }, [id, navigate, setIsLoading]);

    return (
        <div className="w-full h-screen flex flex-col dark:bg-bg_header_dark overflow-hidden">
            <div className="flex justify-start items-center py-2 border-b dark:border-slate-700 dark:shadow-sm bg-bg_header dark:bg-bg_header_dark">
                <button
                    className="top-3 left-5 w-10 h-10
                    rounded-full drop-shadow-lg flex justify-center items-center text-blue-700 text-4xl"
                    onClick={handleBackClick}
                >
                    {/* <img src={backArrow} alt="Back Arrow" /> */}
                    <IoIosArrowBack size={25} className="text-white" />
                </button>
                <div className="flex-1 justify-center items-center">
                    <h3 className="text-white mr-7 text-center">
                        {t("selectedMatches")}
                    </h3>
                </div>
                {/* <div className="pr-3"><img src={Share}></img></div> */}
            </div>
            <div className="text-primary dark:text-league_text text-end text-sm mr-3 mt-1 font-dangrek">BetID : {selectedMatchesDetail?.postingNo}</div>
            <div className="flex-1 p-2 overflow-auto">
                {selectedMatches.map((match, index) => {
                    const bettedTeam = language === "en" ? match?.bettedTeam : match?.bettedTeamMyan;
                    const oppositeTeamName = language === "en" ? match?.oppositeTeamName : match?.oppositeTeamNameMyan;
                    return (
                        <div key={index} className="flex flex-col py-4 px-2 bg-k_groupBgColorEight rounded-md my-2">
                            <div className="flex justify-between items-center mb-2 font-thin text-xs sm:text-sm md:text-base text-white dark:text-white">
                                <p className="text-left rounded-lg text-k_ font-normal dark:text-betID">
                                    {match?.eventDate}
                                </p>
                            </div>
                            <div className="grid grid-cols-9 gap-[1px] mb-1">
                                <div
                                    className={`${match?.home === true
                                        ? "bg-k_selectionBgColor shadow-2xl shadow-blue-300 text-k_selectionFgColor"
                                        : "bg-k_teamsBgColor text-k_teamsFgColor"
                                        } rounded-lg py-2 px-1 flex items-center col-span-4`}
                                >
                                    <h3
                                        className={`ml-auto font-normal text-center  dark:text-white text-sm ${match?.home === true ? "text-k_selectionFgColor" : "text-k_teamsFgColor"
                                            } sm:text-base md:text-base ${match?.isHomeBodyOdd === false
                                                ? "flex flex-1 items-center justify-center"
                                                : "flex flex-1 justify-center"
                                            }`}
                                    >
                                        {match.isHome ? bettedTeam : oppositeTeamName}
                                    </h3>
                                    {match.isHomeBodyOdd === true && (
                                        <h3 className="bg-k_priceBgColor p-1 font-bold rounded-lg text-k_priceFgColor text-xxs text-xs sm:text-sm md:text-base whitespace-nowrap">
                                            {match?.bodyOdd}
                                        </h3>
                                    )}
                                </div>
                                {/* <div className="cursor-not-allowed text-center bg-red-600 rounded-lg p-2 flex justify-center items-center ">
                                    <h3 className="font-semibold text-center text-[#fff] text-base sm:text-sm md:text-base">
                                        {match?.homeResult}
                                    </h3>
                                </div>
                                <div className="cursor-not-allowed text-center bg-red-600 rounded-lg p-2 flex justify-center items-center ">
                                    <h3 className="font-semibold text-center text-[#fff] text-base sm:text-sm md:text-base">
                                        {match?.awayResult}
                                    </h3>
                                </div> */}
                                <div className="flex justify-center items-center text-center text-k_goalscoreFgColor rounded-lg text-sm w-full bg-k_goalscoreBgColor font-dangrek">{`${match?.homeResult}-${match?.awayResult}`}</div>
                                <div
                                    className={`${match?.away === true
                                        ? "bg-k_selectionBgColor shadow-2xl shadow-blue-300 text-k_selectionFgColor"
                                        : "bg-k_teamsBgColor text-k_teamsFgColor"
                                        }  rounded-lg py-2 px-1 flex justify-center items-center col-span-4`}
                                >
                                    {match?.isHomeBodyOdd === false && (
                                        <h3 className="bg-k_priceBgColor p-1 font-bold rounded-lg text-k_priceFgColor text-xxs text-xs sm:text-sm md:text-base">
                                            {match?.bodyOdd}
                                        </h3>
                                    )}
                                    <h3
                                        className={` flex flex-1 w-full justify-center text-center font-normal dark:text-white text-sm sm:text-base md:text-base ${match?.isHomeBodyOdd === true &&
                                            "flex flex-1 justify-center items-center"
                                            }`}
                                    >
                                        {!match.isHome ? bettedTeam : oppositeTeamName}
                                    </h3>
                                </div>
                            </div>
                            <div className="grid grid-cols-5 gap-1">
                                <div
                                    className={`${match?.overs === true
                                        ? "bg-k_selectionBgColor shadow-2xl shadow-blue-300 text-k_selectionFgColor"
                                        : "bg-k_teamsBgColor text-k_teamsFgColor"
                                        } rounded-lg p-1 col-span-2`}
                                >
                                    <h3 className="font-normal text-center  dark:text-white text-sm sm:text-sm md:text-base py-1">
                                        {t("goalOver")}
                                    </h3>
                                </div>
                                <div className="cursor-not-allowed text-center bg-k_priceBgColor rounded-lg p-1 flex justify-center items-center ">
                                    <h3 className="font-bold text-center text-k_priceFgColor text-xs sm:text-sm md:text-base">
                                        {match?.goalOdd}
                                    </h3>
                                </div>
                                <div
                                    className={`${match?.under === true
                                        ? "bg-k_selectionBgColor shadow-2xl shadow-blue-300 text-k_selectionFgColor"
                                        : "bg-k_teamsBgColor text-k_teamsFgColor"
                                        } text-center rounded-lg p-1 col-span-2 flex justify-center items-center`}
                                >
                                    <h3 className="font-normal text-center  dark:text-white text-sm sm:text-sm md:text-base">
                                        {t("goalUnder")}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="w-full flex flex-col bg-bg_header dark:border-t-2 dark:border-slate-700 dark:bg-bg_header_dark text-white h-max pl-20 py-4 font-dangrek">
                <div className="flex justify-end pb-2 pr-6">
                    <p className="font-bold text-xs text-betID">
                        {moment(new Date(selectedMatchesDetail?.createdDate)).format(
                            "DD.MM.YYYY h:mm A"
                        )}
                    </p>
                </div>
                <div className="grid grid-cols-2 gap-4 py-1">
                    <h4>{t("betAmount")}</h4>
                    <p>{parseInt(selectedMatchesDetail?.amount).toLocaleString()}</p>
                </div>
                <div className="grid grid-cols-2 gap-4 py-1">
                    <h4>{t("teamCount")}</h4>
                    <p>{selectedMatchesDetail?.teamCount}</p>
                </div>
            </div>
        </div>
    );
}

export default SelectedMathesDetail;
