import React, { useState, useEffect, useMemo, useCallback } from 'react';
import FilterView from '../filter';
import Sort from '../sort';
import Content from '../body/content';
import Footer from '../body/footer';
import { useNavigate } from 'react-router-dom';
// import bettingService from '../../../api/services/bettingService';
import { useTranslation } from 'react-i18next';
import { getMixEventAsyncAction, setEvents, setMixSelectedLeagues } from '../../common/actions/common.actions';
import { useSelector, useDispatch } from 'react-redux';
import { playSong } from '../../common/actions/common.actions';
// import clear from "../../../assets/icons/clear.png";
import { selectLoginData } from '../login/login.actions';
import { IoIosArrowBack } from "react-icons/io";
import { FiFilter, FiSearch } from "react-icons/fi";
import { LiaSortAmountDownSolid } from "react-icons/lia";
// import { LuRefreshCcw } from "react-icons/lu";
import { setConfirmData } from '../../common/actions/common.actions';
import { MdCancel } from "react-icons/md";

const MaunggComponent = React.memo(({ isLoading, setIsLoading, showToastMessage }) => {
  const navigate = useNavigate();
  const { events, mixSelectedLeagues, mixEvents, confirmData } = useSelector((state) => state.common);
  const { userDetails } = useSelector(selectLoginData);
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [leagues, setLeagues] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [showSorting, setShowSorting] = useState(false);
  const [sort, setSort] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [selectedMatches, setSelectedMatches] = useState([]);
  const [userData, setUserData] = useState(userDetails);
  const [deselectAll, setDeselectAll] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // const handleEvent = (e) => {
    //   e.preventDefault();
    //   // alert("back click");
    //   // navigate('/dashboard');
    // };
    // window.addEventListener('popstate', handleEvent);
    setUserData(userDetails);
    if (confirmData) {
      dispatch(setConfirmData(confirmData));
      setSelectedMatches(confirmData.selectedMatches);
    }
    return () => {
      // window.removeEventListener('popstate', handleEvent);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleSongClick = useCallback(() => {
    dispatch(playSong());
  }, [dispatch]);

  const handleBackClick = useCallback(async () => {
    await handleSongClick();
    navigate('/dashboard');
  }, [handleSongClick, navigate]);

  const getEvent = useCallback(async () => {
    try {
      const res = mixEvents;
      if (res?.islock) {
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        navigate("/login");
      }
      if (!mixEvents || !mixEvents.leagueList || mixEvents.leagueList.length === 0) {
        return;
      }
      const oldLeague = Array.from(leagues);
      const eventData = [];
      res.eventDetails.forEach((item) => {
        item.eventList.forEach((eventItem) => {
          const eventKeys = Object.keys(eventItem);
          const event = {};
          eventKeys.forEach((key) => {
            event[key] = eventItem[key];
          });

          eventData.push({
            ...event,
            leagueName: item.league,
            leagueId: item.leagueId,
          });
        });
      });
      
      const sortedMatches = eventData?.sort((a, b) => {
        return new Date(a.eventTime) - new Date(b.eventTime);
      });
      dispatch(setEvents(sortedMatches));

      const selection = mixSelectedLeagues;
      if (selection && selection !== null && selection.length > 0) {
        const newSelection = [...selection];
        if (mixEvents && mixEvents.leagueList && mixEvents.leagueList.length > 0 && oldLeague.length !== 0) {
          const oldLeagueNames = new Set(oldLeague.map(item => item.leagueName));
          const newLeagues = mixEvents.leagueList.filter(item => !oldLeagueNames.has(item.leagueName));
          if (newLeagues && newLeagues.length > 0) {
            const newLeagueNames = newLeagues.map(c => c.leagueName);
            newSelection.push(...newLeagueNames);
          }
        }
        dispatch(setMixSelectedLeagues(newSelection));
      } else {
        if (mixSelectedLeagues && mixSelectedLeagues.length === 0 && mixEvents && mixEvents.leagueList && mixEvents.leagueList.length > 0 && !deselectAll) {
          dispatch(setMixSelectedLeagues(mixEvents.leagueList.map((league) => league.leagueName)));
        }
      }
    } catch (error) {
      console.log({ error });
    } finally { setIsLoading(false); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixEvents]);

  useEffect(() => {
    const sortedLeagues = mixEvents && mixEvents.length !== 0 ? [...mixEvents.leagueList].sort((a, b) => {
      return a.leagueName.localeCompare(b.leagueName);
    }) : [];
    setLeagues(()=>sortedLeagues);
  }, [mixEvents]);

  useEffect(() => {
    const validEventIDs = events.map((x) => x.eventId);
    if (confirmData && confirmData.selectedMatches && confirmData.selectedMatches.length > 0) {
      const validConfirmData = confirmData.selectedMatches.filter(x => validEventIDs.includes(x.eventId));
      dispatch(setConfirmData({ betAmount: confirmData.betAmount, selectedMatches: validConfirmData }));
    }
    if (selectedMatches && selectedMatches.length > 0) {
      const validSelectedMatches = selectedMatches.filter(x => validEventIDs.includes(x.eventId));
      setSelectedMatches(validSelectedMatches);
    }
  }, [events]);

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  useEffect(() => {
    const getFetchData = async () => {
      if (userData) {
        setIsLoading(true);
        await dispatch(getMixEventAsyncAction());
      }
    }
    getFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredMatches = useMemo(() => {
    // Filter events based on the search input
    const filtered = searchInput
      ? events.filter((event) => {
        const searchTerm = searchInput?.toLowerCase();
        return (
          event.leagueName.toLowerCase().includes(searchTerm) ||
          event.homeTeam.toLowerCase().includes(searchTerm) ||
          event.homeTeamMyan.toLowerCase().includes(searchTerm) ||
          event.awayTeam.toLowerCase().includes(searchTerm) ||
          event.awayTeamMyan.toLowerCase().includes(searchTerm)
        );
      })
      : events;

    let sortedMatches = filtered;
    if (sort !== null) {
      if (sort === "leagueName") {
        sortedMatches = [...filtered].sort((a, b) => {
          return a[sort].localeCompare(b[sort]);
        });
      } else if (sort === "eventTime") {
        sortedMatches = [...filtered].sort((a, b) => {
          return new Date(a.eventTime) - new Date(b.eventTime);
        });
      }
      // handleSetEvents(sortedMatches);
    }
    return sortedMatches;
  }, [events, searchInput, sort]);

  const handleFilterClick = async () => {
    await handleSongClick();
    setShowFilter(!showFilter);
  };

  const handleFilterApply = () => {
    setShowFilter(false);
  };

  const handleLeagueSelection = (mixSelectedLeagues) => {
    setDeselectAll(mixSelectedLeagues.length === 0);
    dispatch(setMixSelectedLeagues(mixSelectedLeagues));
  };

  const handleSortingSelection = useCallback((selectedSort) => {
    setSort(selectedSort);
  }, []);

  const handleSortingClick = async () => {
    await handleSongClick();
    setShowSorting(!showSorting);
  };

  const handleSortingApply = () => {
    setShowSorting(false);
  };

  const handleSearchClick = async () => {
    await handleSongClick();
    setShowSearch(!showSearch);
  };

  const handleSearchInputChange = useCallback((event) => {
    setSearchInput(() => event.target.value);
  }, []);

  // const handleRefreshClick = useCallback(async () => {
  //   getEvent(true);
  // }, [getEvent])


  const handleSelectTeamFun = useCallback((choosen, match, maxTeam = 11, fromBody = false) => {
    setSelectedMatches((prevSelectedMatches) => {
      const ind =
        prevSelectedMatches && prevSelectedMatches.length > 0
          ? prevSelectedMatches.findIndex((x) => x.eventId === match.eventId)
          : -1;
      const isSameItem =
        (prevSelectedMatches && prevSelectedMatches.length > 0
          ? prevSelectedMatches.findIndex((x) => x.eventId === match.eventId && x.choosen === choosen)
          : -1) >= 0;
      if (!isSameItem && (!prevSelectedMatches || prevSelectedMatches.length < maxTeam)) {
        if (prevSelectedMatches && prevSelectedMatches.length > 0) {
          return [
            ...(ind >= 0
              ? prevSelectedMatches.filter((x) => x.eventId !== match.eventId)
              : prevSelectedMatches),
            { choosen, ...match },
          ];
        } else {
          return [{ choosen, ...match }];
        }
      } else {
        if (fromBody && !isSameItem) {
          return [{ choosen, ...match }];
        } else {
          const newSelectedSameEvent = prevSelectedMatches.some((x) => x.eventId === match.eventId && x.choosen !== choosen);
          if (newSelectedSameEvent) {
            return [
              ...(ind >= 0
                ? prevSelectedMatches.filter((x) => x.eventId !== match.eventId)
                : prevSelectedMatches),
              { choosen, ...match },
            ];
          }
          const tmpData = prevSelectedMatches.filter((x) => x.eventId !== match.eventId);
          return tmpData;
        }
      }
    })
  }, []);

  const handleSelectTeam = useMemo(() => handleSelectTeamFun, [handleSelectTeamFun]);

  const handleRemoveCard = (rapidEventId) => {
    const newData = selectedMatches.filter(x => x.rapidEventId !== rapidEventId);
    dispatch(setConfirmData({ betAmount: confirmData.betAmount, selectedMatches: newData }));
    setSelectedMatches(newData);
  }

  const handleClearSearch = () => {
    handleSongClick();
    setSearchInput(''); // Clear the search input
    setShowSearch(false);
  };

  const isWideWindow = window.innerWidth >= 1024;

  return (
    <div className="w-full h-screen flex flex-col overflow-hidden relative bg-[#fff] dark:bg-bg_header_dark ">
      <div>
        <div className="w-full py-2 flex justify-between items-center border-b border-slate-400 bg-bg_header dark:bg-bg_header_dark">
          <button
            className="top-3 left-3 w-10 h-10 mr-5 ml-3 flex justify-center items-center text-white text-4xl"
            onClick={handleBackClick}
          >
            <IoIosArrowBack size={25} />
          </button>
          {showSearch ? (
            ''
          ) : (
            <div className='w-full'>
              <h3 className="text-white">{t('maungg')}</h3>
            </div>
          )}
          {showSearch ? (
            <div className="w-full relative flex items-center">
              <input
                type="text"
                className="border font-breeSerif bg-search w-full p-2 pl-10 focus:outline-none focus:ring-0 rounded-3xl"
                placeholder="Search"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              <FiSearch size={18} className="absolute left-4" />
              <button
                className="absolute right-4"
                onClick={handleClearSearch}
              >
                {/* <img src={clear} alt="Clear" className="w-5 h-5" /> */}
                <MdCancel size={21} className="text-gray-400" />
              </button>
            </div>
          ) : (
            ''
          )}
          <div className="flex justify-between pr-1">
            {showSearch ? (
              ''
            ) : (
              <button onClick={handleSortingClick} className='text-white px-2 '>
                <LiaSortAmountDownSolid size={22} />
              </button>
            )}
            {
              showSearch ? (
                ''
              ) : (
                <button onClick={handleSearchClick} className='text-white px-2'>
                  <FiSearch size={21} />
                </button>
              )
            }

            {showSearch ? (
              ''
            ) : (
              <button onClick={handleFilterClick} className='text-white px-2'>
                <FiFilter size={20} />
              </button>
            )}
            {/* {showSearch ? (
              ''
            ) : (
              <div className="text-white px-2" onClick={handleRefreshClick}>
                <LuRefreshCcw size={21} className={`${isLoading ? 'animate-spin' : ''}`} />
              </div>
            )} */}
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-auto">
        <ul className="w-full flex flex-col items-center scroll-smooth list-none p-0" data-aos="fade-up">
          {/* Scrollable content */}
          {filteredMatches && filteredMatches.map((match, index) => (
            <React.Fragment key={index}>
              {mixSelectedLeagues && mixSelectedLeagues.map((league) => {
                if (league === match.leagueName) {
                  return (
                    <Content
                      key={index}
                      match={match}
                      sendDataToParent={handleSelectTeam}
                      selectedMatches={selectedMatches}
                      eventTime={match.eventTime}
                      homeTeam={match.homeTeam}
                      awayTeam={match.awayTeam}
                      bodyHandicap={match.bodyHandicap}
                      goalHandicap={match.goalHandicap}
                    />
                  );
                }
                return null;
              })}
            </React.Fragment>
          ))}

        </ul>
      </div>
      <Footer
        selectedMatches={selectedMatches}
        fromBody={false}
        showToastMessage={showToastMessage}
        handleRemoveCard={handleRemoveCard}
      />
      <div>
        <div
          className={`absolute top-12 rounded dark:border-none border-2 dark:text-white right-2 bg-white p-4 dark:bg-bg_header_dark shadow-lg font-dangrek ${showSorting ? 'block' : 'hidden'
            }`}
        >
          <Sort onClose={handleSortingApply} onSortingSelection={handleSortingSelection} />
        </div>
      </div>
      <div
        className={`fixed top-0 left-0 w-full ${isWideWindow ? 'w-[85%] left-60' : ''} h-screen bg-white z-50 transform transition-transform ${showFilter ? 'translate-y-0' : '-translate-y-full'
          }`}
      >
        <FilterView
          key={leagues.leagueId}
          onClose={handleFilterApply}
          leagues={leagues}
          onLeagueSelection={handleLeagueSelection}
          events={events.length}
          mixBody="maung"
        />
      </div>
    </div>
  );
});

export default MaunggComponent;
