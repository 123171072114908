import React from "react";
import Logo from "../../../../assets/icons/logoNew.png";

const Modal = ({ title, content, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* <div className="modal-backdrop fixed inset-0 bg-black opacity-5"></div> */}
      <div className="modal-container w-80 mx-4 rounded-xl shadow-custom">
        <div className="flex flex-row gap-1 bg-primary/[0.8] dark:bg-primary/[0.8] backdrop-blur-md rounded-t-xl text-white justify-start items-center modal-header text-xl font-semibold p-2 border-b">
          <img className="pl-2" src={Logo} alt="logo" />
          {title.toUpperCase()}
        </div>
        <div className="bg-white/[0.6] backdrop-blur-md rounded-b-xl">
          <div className="flex justify-center modal-body p-3">
            <p className="p-1">{content}</p>
          </div>
          <div className="modal-footer pb-3 flex justify-end pr-5">
            <button
              className="bg-primary/[0.8] text-white font-bold py-2 px-8 rounded"
              onClick={onClose}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
