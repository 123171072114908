import React from 'react'

const Loading = () => {
  return (
    // <div className="flex w-full h-full z-[1000] justify-center items-center absolute">
    //   <div className="flex justify-center items-center w-[150px] h-[150px] bg-gray-200/[0.30] dark:bg-gray-700/[0.5] shadow-lg rounded-md ">
    //     <Spinner className="w-[50px] h-[50px]" />
    //   </div>
    // </div>
    <div className="flex w-full h-full z-[1000] justify-center items-center absolute">
      <div className="flex justify-center items-center w-[150px] h-[150px] bg-slate-100/[0.2] dark:bg-gray-700/[0.5] shadow-lg rounded-md">
        <div className=" border-t-4 border-primary dark:border-primary border-solid h-24 w-24 rounded-full animate-spin"></div>
      </div>
    </div>
  );
}

export default Loading