import React from 'react';
import { Navigate } from 'react-router-dom';

const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    return token ? true : false;
}

const withAuth = (Component) => {
  return (props) => {
    if (isAuthenticated()) {
      return <Component {...props} />;
    } else {
      return <Navigate to="/login" />;
    }
  };
};

export default withAuth;
