import { useNavigate } from 'react-router';
import login_Service from '../../../api/services/login_Service';
import { useEffect, useState } from 'react';
import forwardArrow from "../../../assets/icons/forward.svg";
import Modal from "../../common/components/modal/index";
import { useDispatch } from 'react-redux';
import { playSong } from '../../common/actions/common.actions';
import { IoIosArrowBack } from "react-icons/io";

const Rules = ({ setIsLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rules, setRules] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const loggedInParam = queryParams.get("loggedIn");
  const loggedIn = loggedInParam === "true";
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleClick = () => {
    handleSongClick();
    navigate('/dashboard');
  };

  const handleButtonClick = (redirectToDashboard) => {
    if (redirectToDashboard) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    setIsLoading(true);
    login_Service.rule()
      .then(response => response.json())
      .then(data => {
        if (data) {
          setRules(data)
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err)
      });
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col py-4 justify-between  h-screen w-full bg-white dark:bg-background bg-opacity-90 overflow-hidden">
      <div className="flex pt-4 justify-start items-start">
        <button
          className="left-10 w-10 h-10 mr-3 rounded-full drop-shadow-lg flex justify-start items-start text-blue-700 text-4xl"
          onClick={handleClick}
        >
          {/* {loggedInParam ? '' : <img src={forwardArrow} alt="Back Arrow" />} */}
          {loggedInParam ? '' : <IoIosArrowBack size={25} className="text-content ml-2" />}
        </button>
        <div className="flex flex-1 justify-center items-center font-dangrek text-primary dark:text-white text-2xl font-400">
          Rule & Regulation
        </div>
      </div>
      <div className="flex-1 items-center overflow-auto px-2">
        {rules.map((rule) => (
          <div key={rule.ruleId} className="mt-4 p-2 dark:text-white">
            <h3 className="pb-2">{rule?.ruleTitle}</h3>
            <p>{rule?.descriptions}</p>
          </div>
        ))}
      </div>
      {loggedIn && (
        <div className=" z-50 w-full h-20">
          <div className="flex justify-end items-end md:justify-center space-x-4 p-4">
            <button
              className="p-2 border-[1px] border-border_rule_btn/10 bg-football_text/[0.15] rounded-[7px] text-text_rule_btn shadow-rule"
              onClick={() => handleButtonClick(false)}
            >
              ထွက်မည်
            </button>
            <button
              className="p-2 border-[1px] border-border_rule_btn/10 bg-football_text/[0.15] rounded-[7px] text-content shadow-rule"
              onClick={() => handleButtonClick(true)}
            >
              သဘောတူပါသည်
            </button>
          </div>
        </div>
      )}
      {/* {isModalOpen && loggedIn && (
        <Modal title="SPORT 909" content="Login Success" onClose={closeModal} />
      )} */}
    </div>
  );
};

export default Rules;
