import React, { useState } from "react";
import forwardArrow from "../../../assets/icons/forward.svg";
import Card from "./card";
import accountService from "../../../api/services/accountService";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Schedule from "../../../assets/icons/Schedule.svg";
import { useDispatch, useSelector } from "react-redux";
import { playSong } from "../../common/actions/common.actions";
import { selectLoginData } from "../login/login.actions";
import { FiSearch } from "react-icons/fi";
import { BiSolidCalendar } from "react-icons/bi";
import whiteSchedule from '../../../assets/icons/whiteSchedule.svg';

const Account = ({ closeSidebar, setIsLoading }) => {
  const [transactionData, setTransactionData] = useState([]);
  const today = new Date();
  const [dateRange, setDateRange] = useState([today, today]);
  const [startDate, endDate] = dateRange;
  const [transactionsAvailable, setTransactionsAvailable] = useState(true);
  const { userDetails } = useSelector(selectLoginData);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleClick = () => {
    handleSongClick();
    navigate("/dashboard");
  };

  const handleDateChange = (update) => {
    setDateRange(update);
  };

  const handleSearchClick = () => {
    fetchData(startDate, endDate);
  }

  const fetchData = async (start, end) => {
    if (start && end) {
      setIsLoading(true);
      const formattedStartDate = moment(start).format("YYYY-MM-DD");
      const formattedEndDate = moment(end).format("YYYY-MM-DD");
      try {
        const response = await accountService.getTransactionsForUser({
          userId: Number(userDetails.userId),
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });
        const data = await response.json();

        if (data?.userTransactions) {
          const filteredData = data?.userTransactions.filter(
            (transaction) =>
              moment(transaction?.date).isSameOrAfter(start, "day") &&
              moment(transaction?.date).isSameOrBefore(end, "day")
          );
          setTransactionData(filteredData);
          setTransactionsAvailable(filteredData.length > 0);
        } else {
          setTransactionsAvailable(false);
        }
      } catch (error) {
        console.error("API request error:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="w-full h-screen flex flex-col font-dangrek overflow-hidden dark:bg-bg_header_dark">
      <div className="flex justify-between items-center text-center py-2 pr-5 bg-bg_header dark:bg-bg_header_dark border-b dark:border-b dark:border-border_dark/[0.7]">
        <div className="flex items-center">
          <button
            className="top-3 left-3 w-10 h-10 mr-3
                    rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl"
            onClick={handleClick}
          >
            <img src={forwardArrow} alt="Back Arrow" />
          </button>
          <h3 className="text-white">{t("statement")}</h3>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto">
        <div className="flex justify-end m-3">
          <label
            htmlFor="accountDate"
            className="flex flex-row border border-white/[0.5] dark:bg-white/[0.15] py-2 px-3 rounded-md shadow-md justify-center items-center"
          >
            {/* <img src={Schedule} alt="DarkSchedule" className="mr-2 dark:hidden" />
            <img src={whiteSchedule} alt="WhiteSchedule" className="mr-2 hidden dark:block" /> */}
            <BiSolidCalendar size={19} className="dark:text-white mr-2" />
            <h3 className=" text-primary dark:text-white/[0.7] font-myanmarSansPro">
              {moment(startDate).format("DD-MM-YYYY")} / {moment(endDate).format("DD-MM-YYYY")}
            </h3>
          </label>
          <DatePicker
            id="accountDate"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            withPortal
            className="rounded bg-white hidden"
          />
          <div className="flex items-center bg-white border border-white/[0.5] dark:bg-white/[0.15] ml-4 p-3 mr-2 rounded-md shadow-md cursor-pointer" onClick={handleSearchClick}>
            <FiSearch size={18} className="dark:text-white" />
          </div>
        </div>
        {transactionsAvailable ? (
          transactionData?.map((data, index) => (
            <Card data={data} key={index} />
          ))
        ) : (
          <div className="flex justify-center items-center h-full">
            <p className="text-sm dark:text-white">{t("noTransaction")}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Account;
