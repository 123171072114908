import React from "react";
import {
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import forwardArrow from "../../../assets/icons/forward.svg";
import { useNavigate } from "react-router-dom";
import { playSong } from '../../common/actions/common.actions';
import { useDispatch } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";

export const Share = () => {
    const shareUrl = window.location.href.split('/').slice(0, 3).join('/');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSongClick = async () => {
        dispatch(playSong());
    };

    const handleClick = () => {
        handleSongClick();
        navigate('/dashboard');
    };

    return (
        <div className="w-full h-screen flex flex-col">
            <div className="p-2">
                <button
                    className="top-3 left-3 w-10 h-10 mr-3
                    rounded-full drop-shadow-lg flex justify-center items-center text-blue-700 text-4xl"
                    onClick={handleClick}
                >
                    {/* <img src={forwardArrow} alt="Back Arrow" /> */}
                    <IoIosArrowBack size={25} className="text-content" />
                </button>
            </div>
            <div className="flex flex-1 justify-center items-center">
                <FacebookShareButton url={shareUrl}>
                    <FacebookIcon size={40} round={true} />
                </FacebookShareButton>
                <FacebookMessengerShareButton url={shareUrl}>
                    <FacebookMessengerIcon size={40} round={true} />
                </FacebookMessengerShareButton>
                <TwitterShareButton url={shareUrl}>
                    <TwitterIcon size={40} round={true} />
                </TwitterShareButton>
                <TelegramShareButton url={shareUrl}>
                    <TelegramIcon size={40} round={true} />
                </TelegramShareButton>
                <WhatsappShareButton url={shareUrl}>
                    <WhatsappIcon size={40} round={true} />
                </WhatsappShareButton>
                <ViberShareButton url={shareUrl}>
                    <ViberIcon size={40} round={true} />
                </ViberShareButton>
            </div>
        </div>
    );
};
