const LogoutHelper = () => {
  // Remove the token from localStorage
  // localStorage.clear();
  const items = ['rememberMe', 'darkMode', 'credential', 'soundOn'];

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!items.includes(key)) {
      localStorage.removeItem(key);
    }
  }
};

export default LogoutHelper;
