import React, { useState } from "react";
// import Light from "./assets/icons/light.svg";
// import Dark from "./assets/icons/dark.svg";
import { PiMoon } from "react-icons/pi";
import { BsSun } from "react-icons/bs";

function DarkThemeToggleComponent() {
  //eslint-disable-next-line
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") == "true"
  );

  const toggleDarkMode = () => {
    const darkTheme = localStorage.getItem("darkMode");
    //eslint-disable-next-line
    if (darkTheme == "true") {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("darkMode", false);
      setDarkMode(false);
    } else {
      document.documentElement.classList.add("dark");
      localStorage.setItem("darkMode", true);
      setDarkMode(true);
    }
  };

  return (
    <>
      {
        // darkMode ? <img src={Light} alt="light" className="w-7 cursor-pointer" onClick={toggleDarkMode}></img> : <img src={Dark} alt="dark" className="w-7" onClick={toggleDarkMode}></img>
        darkMode ? (
          // <img src={Light} alt="light" className="w-7" onClick={toggleDarkMode}></img>
          <div onClick={toggleDarkMode}>
            <BsSun size={28} className="dark:text-white" />
          </div>
        ) : (
          // <img src={Dark} alt="dark" className="w-7" onClick={toggleDarkMode}></img>
          <div onClick={toggleDarkMode}>
            <PiMoon size={28} />
          </div>
        )
      }
    </>
  );
}

export default DarkThemeToggleComponent;
