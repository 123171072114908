import React from "react";
import { useTranslation } from "react-i18next";
import TrashIcon from "../../../assets/icons/Trash.svg";
import * as moment from 'moment';
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { playSong } from "../../common/actions/common.actions";
import { BiFootball } from 'react-icons/bi';


const Content = ({ eventTime, homeTeam, awayTeam, bodyHandicap, goalHandicap, match, sendDataToParent, selectedMatches = [], fromSelectedPage = false, handleRemoveCard, isFivePercent }) => {

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleClick = (choosen) => {
    handleSongClick();
    sendDataToParent(choosen, match);
  };

  const isSelected = selectedMatches?.some(
    (x) => x.eventId === match.eventId
  );

  // Determine if the match is selected and chosen as 'home'
  const isHomeChosen =
    isSelected && selectedMatches?.some((x) => x.eventId === match.eventId && x.choosen === "home");

  // Determine if the match is selected and chosen as 'away'
  const isAwayChosen =
    isSelected && selectedMatches?.some((x) => x.eventId === match.eventId && x.choosen === "away");

  // Determine if the match is selected and chosen as 'over'
  const isOverChosen =
    isSelected && selectedMatches?.some((x) => x.eventId === match.eventId && x.choosen === "over");

  // Determine if the match is selected and chosen as 'under'
  const isUnderChosen =
    isSelected && selectedMatches?.some((x) => x.eventId === match.eventId && x.choosen === "under");

  const removeCard = async () => {
    await handleSongClick();
    handleRemoveCard(match.rapidEventId);
  }

  return (
    <li className={`w-[98%] m-auto px-2 rounded-md border-slate-300 bg-k_groupBgColorEight py-4 my-1 ${location.pathname === '/body' && (isFivePercent ? 'bg-k_groupBgColorFive' : '')}`}>
      {/* Card ရဲ့ Border Color      နဲ့ ==========    Card ရဲ့ BgColor  */}
      <div className="flex flex-col">
        <div className="flex justify-between items-center mb-1 text-league_text">
          <p className="text-left whitespace-nowrap font-normal font-inter text-xs md:text-sm bg-k_dtBgColor text-k_dtFgColor rounded-md p-1">
          {/* DateTime ရဲ့ Color*/}
            {moment(new Date(match?.eventTime)).format("DD.MM.YYYY h:mm A")}
          </p>
          {fromSelectedPage ? (
            <img src={TrashIcon} alt="" onClick={removeCard} className=" cursor-pointer" />
          ) : (
            <div className={`flex items-center bg-k_leagueBgColor px-2 py-1 rounded-lg  ${location.pathname === '/body' && (isFivePercent ? 'bg-k_leagueBgColor' : '')}`}>
              {/* Leagues ရဲ့ BgColor*/}
              <BiFootball size={15} className="text-k_leagueFgColor" />
              <p className={`pl-2 text-xs font-normal  text-k_leagueFgColor ${location.pathname === '/body' && (isFivePercent ? 'text-k_leagueFgColor' : '')}`}>
              {/* Leagues ရဲ့ FgColor*/}
                {match.leagueName}
              </p>
            </div>
          )}
        </div>
        <div
          className={`grid grid-cols-2 gap-1 mb-1 ${fromSelectedPage ? " pointer-events-none" : ""
            }`}
        >
          <div
            onClick={() => handleClick("home")}
            className={`${isHomeChosen ? "bg-k_selectionBgColor shadow-2xl shadow-blue-300 text-k_selectionFgColor" : "bg-bg_league_dark"
              } cursor-pointer rounded-lg py-[10px] px-1 flex items-center text-center justify-center`}
          >
            {/* HomeTeams ရဲ့ Selection BgColor*/}
            <h3
              className={`ml-auto font-normal  ${isHomeChosen ? "text-k_selectionFgColor" : "text-k_selectionFgColor"
                } text-sm sm:text-base md:text-base flex flex-1 justify-center`}
            >
              {/* HomeTeams ရဲ့ Selection FgColor*/}
              {i18n.language === "en" ? match.homeTeam : match.homeTeamMyan}
            </h3>
            {match.overId === match.homeId && (
              <h3 className="bg-k_priceBgColor p-1 font-mono rounded-lg ml-auto font-bold text-k_priceFgColor text-xxs text-xs sm:text-sm md:text-base">
                {/* HomeTeams ဘက်က ကြေး FgColor*/}
                {match.bodyHandicap}
              </h3>
            )}
          </div>
          <div
            onClick={() => handleClick("away")}
            className={`${isAwayChosen ? "bg-k_selectionBgColor shadow-2xl shadow-blue-300 text-k_selectionFgColor" : "bg-k_teamsBgColor"
              } 
          cursor-pointer rounded-lg py-[10px] px-1 flex items-center text-center justify-center`}
          >
            {/* AwayTeams ရဲ့ Selection BgColor*/}
            {match.overId === match.awayId && (
              <h3 className="bg-k_priceBgColor  whitespace-nowrap p-1 font-mono  font-bold rounded-lg mr-auto text-k_priceFgColor text-xxs text-xs sm:text-sm md:text-base">
                {/* AwayTeams ဘက်က ကြေး FgColor*/}
                {match.bodyHandicap}
              </h3>
            )}
            <h3
              className={`font-normal text-football ${isAwayChosen ? "text-k_teamsFgColor" : "text-k_teamsFgColor"
                } dark:text-white text-sm sm:text-base md:text-base w-full`}
            >
              {/* AwayTeams ရဲ့ Selection FgColor*/}
              {i18n.language === "en" ? match.awayTeam : match.awayTeamMyan}
            </h3>
          </div>
        </div>
        <div
          className={`grid grid-cols-5 gap-1 ${fromSelectedPage ? "pointer-events-none" : ""
            }`}
        >
          <div
            onClick={() => handleClick("over")}
            className={`${isOverChosen ? "bg-k_selectionBgColor shadow-2xl shadow-blue-300" : "bg-k_teamsBgColor"
              } 
          cursor-pointer rounded-lg py-[7px] px-1 col-span-2`}
          >
            {/* GoalOver ရဲ့ Selection BgColor*/}
            <h3
              className={`font-normal text-center ${isOverChosen ? "text-k_selectionFgColor" : "text-k_selectionFgColor"
                } text-sm sm:text-sm md:text-base py-1`}
            >
              {/* GoalOver ရဲ့ Selection FgColor*/}
              {t('goalOver')}
            </h3>
          </div>
          <div className="cursor-not-allowed text-center bg-k_priceBgColor rounded-lg p-1 flex justify-center items-center ">
          {/* ဂိုးပေါင်းစျေး ရဲ့  BgColor*/}
            <h3 className="font-mono text-center text-k_priceFgColor  font-bold text-xs sm:text-sm md:text-base">
              {/* ဂိုးပေါင်းစျေး ရဲ့  FgColor*/}
              {match.goalHandicap}
            </h3>
          </div>
          <div
            onClick={() => handleClick("under")}
            className={`${isUnderChosen ? "bg-k_selectionBgColor shadow-2xl shadow-blue-300" : "bg-k_teamsBgColor"
              }
          cursor-pointer text-center rounded-lg py-[7px] p-1 col-span-2 flex justify-center items-center`}
          >
            {/* GoalUnder ရဲ့ Selection BgColor*/}
            <h3
              className={`font-normal text-center ${isUnderChosen ? "text-k_selectionFgColor" : "text-k_selectionFgColor"
                } text-sm sm:text-sm md:text-base`}
            >
              {/* GoalUnder ရဲ့ Selection FgColor*/}
              {t('goalUnder')}
            </h3>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Content;