import client from '../client';
import { apiList } from '../apiList';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    // returns event list
    todayEvent: (payload) => client.post(apiList.todayEvent, { json: payload }),
    // returns list bettingOldHistoryof all the people
    bettingMatchesHistory: () => client.get(apiList.bettingHistory),
    userBettingCheck: (payload) => client.post(apiList.checkBettingOdd, { json: payload }),
    predictProfit: (payload) => client.post(apiList.predictprofit, { json: payload }),
    confirmBetting: (payload) => client.post(apiList.confirmBetting, { json: payload }),
    bettingHistoryDetails: (payload) => client.post(apiList.bettingHistoryDetails, { json: payload }),
    getServerTime: () => client.get(apiList.getServerTime),
    getMinMax: () => client.get(apiList.betAmountLimitGet),
};