import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openSideBar: false,
  theme: "dark",
  oldHistory: localStorage.getItem("oldHistory")
  ? JSON.parse(localStorage.getItem("oldHistory"))
  : localStorage.getItem("oldHistory"),
  selectedMatchesDetail: localStorage.getItem("selectedMatchesDetail")
  ? JSON.parse(localStorage.getItem("selectedMatchesDetail"))
  : localStorage.getItem("selectedMatchesDetail"),
  balance: null,
};

export const sidebarAction = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setOpen: (state) => {
      state.openSideBar = !state.openSideBar;
    },
    setOldHistory: (state, action) => {
      state.oldHistory = action.payload;
      if (action.payload) {
        localStorage.setItem("oldHistory", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("oldHistory");
      }
    },
    setSelectedMatchesDetail: (state, action) => {
      state.selectedMatchesDetail = action.payload;
      if (action.payload) {
        localStorage.setItem("selectedMatchesDetail", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("selectedMatchesDetail");
      }
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setTheme: (state) => {
      state.theme === "dark"
        ? document.documentElement.classList.remove("dark")
        : document.documentElement.classList.add("dark");
      state.theme = state.theme === "dark" ? "light" : "dark";
    },
  },
});

export const { setOpen, setTheme, setOldHistory, setSelectedMatchesDetail, setBalance } = sidebarAction.actions;
export const selectSidebar = (state) => state.sidebar;

export default sidebarAction.reducer;
