import React from "react";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import config from "../../../config/config";
import { BiFootball } from "react-icons/bi";

export const GoalScoreCard = ({ data }) => {
  const { i18n } = useTranslation();
  // let previousLeague = "";

  return (
    <div className={`w-full flex flex-col px-2 py-1`}>
      {data?.map((firstEvent, k) => {
        // const sameLeague = firstEvent.league === previousLeague;
        // previousLeague = firstEvent.league;
        return (
          <div
            key={`${k}`}
            className={`w-full p-1 rounded-md shadow-md mt-1 border-2 border-gray-200 dark:border-gray-700 ${k % 2 === 0 ? "bg-goal_card dark:bg-bg_header_dark" : ""
              }`}
          >
            {/* {!sameLeague && (
              <div className="flex justify-end items-center text-xs mt-2">
                <div className="text-league_text rounded-lg bg-bg_league py-1 px-2">
                  <p>{firstEvent?.league}</p>
                </div>
              </div>
            )} */}
            <div className="flex flex-row justify-between items-center">
              <p className="font-inter text-league_text_dark text-xs">
                {moment(new Date(firstEvent?.event)).format(
                  "DD-MM-YYYY h:mm A"
                )}
              </p>
              <div className="flex justify-end items-center text-xs mt-2 mb-3">
                <div className="text-black rounded-lg bg-bg_league dark:bg-slate-300 py-1 px-2">
                  <p>{firstEvent?.league}</p>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center py-3 font-myanmarSansPro">
              <div className="w-1/3 text-center pr-0">
                <h4>
                  {i18n.language === "en"
                    ? firstEvent?.homeTeam
                    : firstEvent?.homeTeamMyan}
                </h4>
              </div>
              <div className="w-4">
                <BiFootball size={18} className="text-black dark:text-white" />
              </div>
              <div className="w-[41px] text-sm text-center font-dangrek">
                <h4 className="whitespace-nowrap">
                  {firstEvent?.homeGoal} - {firstEvent?.awayGoal}
                </h4>
                <p className=" text-content">
                  {config.goal_status[firstEvent?.status]}
                </p>
              </div>
              <div className="w-4">
                <BiFootball size={18} className="text-black dark:text-white" />
              </div>
              <div className="w-1/3 text-center">
                <h4>
                  {i18n.language === "en"
                    ? firstEvent?.awayTeam
                    : firstEvent?.awayTeamMyan}
                </h4>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
