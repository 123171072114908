// NewsDetail.js
import React from "react";
import { IoIosArrowBack, IoMdClose } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { playSong } from "../../common/actions/common.actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Modal from "react-modal";

const NewsDetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { i18n } = useTranslation();
    const { state } = location;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSongClick = async () => {
        dispatch(playSong());
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleBackClick = () => {
        handleSongClick();
        navigate(-1);
    };

    const handlePhotoClick = () => {
        closeModal();
    };

    return (
        <div className="w-full h-screen flex flex-col dark:text-white dark:bg-black overflow-hidden relative">
            <div className="flex items-center p-2 bg-white dark:bg-black">
                <button
                    className="top-3 left-3 w-10 h-10 mr-3
                    rounded-full drop-shadow-lg flex justify-center items-center dark:text-white text-primary text-4xl"
                    onClick={handleBackClick}
                >
                    <IoIosArrowBack size={25} />
                </button>
                <h3>NEWS DETAILS</h3>
            </div>
            <div className="flex flex-col flex-1 px-6 overflow-auto">
                <div className="py-2">
                    {state && state.news && (
                        <>
                            <img src={state.news.hotNewsFilePath} alt="News" className="max-w-full h-auto pb-4 cursor-pointer" onClick={openModal} />
                            <h3 className="pb-4 text-lg leading-8">
                                {state.news?.hotNewsTitle}
                            </h3>
                            <p className="pb-4 text-news text-md leading-7 text-justify indent-9">
                                {state.news?.hotNewsBody}
                            </p>
                        </>
                    )}
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Photo Modal"
                className=" bg-gray-700/[0.4] dark:bg-gray-800/[0.4] flex w-screen h-screen backdrop-blur-sm justify-center align-middle items-center"
            >
                <div onClick={closeModal} className=" cursor-pointer bg-primary/5 p-1 text-primary dark:text-white rounded-full absolute top-2 right-2">
                    <IoMdClose size={30} />
                </div>
                <img
                    src={state.news.hotNewsFilePath}
                    alt="Full Size"
                    className="w-full max-w-full"
                    onClick={handlePhotoClick}
                />
            </Modal>
        </div>
    );
};

export default NewsDetail;
