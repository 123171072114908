import React, { useEffect, useState, useCallback } from 'react';
import league from '../../../assets/icons/League.svg';
import tune from '../../../assets/icons/Tune.svg';
import okIcon from '../../../assets/icons/Ok.svg';
import { setMixSelectedLeagues, setBodySelectedLeagues} from '../../common/actions/common.actions';
import { useSelector, useDispatch } from 'react-redux';
import { playSong } from '../../common/actions/common.actions';
import { MdOutlineTune } from "react-icons/md";
import { BsFillCheckCircleFill } from "react-icons/bs";

const FilterView = ({ onClose, leagues, onLeagueSelection, events, mixBody }) => {
  const dispatch = useDispatch();
  const { mixSelectedLeagues, bodySelectedLeagues  } = useSelector((state) => state.common);
  const [allLeaguesSelected, setAllLeaguesSelected] = useState(true);
  const isWideWindow = window.innerWidth >= 1024;

  const setSelectedLeagueData = useCallback((data) => {
    mixBody === 'maung' ? dispatch(setMixSelectedLeagues(data)) : dispatch(setBodySelectedLeagues(data));
  }, [dispatch]);

  useEffect(() => {
    const selectedLeagues = mixBody === 'maung' ? mixSelectedLeagues : bodySelectedLeagues;
    if (leagues && leagues.length > 0 && selectedLeagues && selectedLeagues.length > 0) {
      const leagueNames = leagues.map((league) => league.leagueName);

      const isSelectAll =
        leagueNames.every((leagueName) => selectedLeagues.includes(leagueName)) &&
        selectedLeagues.every((leagueName) => leagueNames.includes(leagueName));
      if (isSelectAll === true) {
        setAllLeaguesSelected(true);
      } else {
        setAllLeaguesSelected(false);
      }
      setSelectedLeagueData(selectedLeagues);
    }
  }, [leagues]);
  const handleSongClick = async () => {
    dispatch(playSong());
  };

  const handleCheckboxChange = (leagueName) => {
    if (allLeaguesSelected) {
      const updatedLeagues = leagues.reduce((acc, league) => {
        if (league.leagueName !== leagueName) {
          acc.push(league.leagueName);
        }
        return acc;
      }, []);
      setSelectedLeagueData(updatedLeagues);
      setAllLeaguesSelected(false);
    } else {
      const selectedLeagues = mixBody === 'maung' ? mixSelectedLeagues : bodySelectedLeagues;
      setSelectedLeagueData(selectedLeagues.includes(leagueName)
          ? selectedLeagues.filter((name) => name !== leagueName)
          : [...selectedLeagues, leagueName]
      );
    }
  };

  useEffect(()=>{
    const selectedLeagues = mixBody === 'maung' ? mixSelectedLeagues : bodySelectedLeagues;
    if (selectedLeagues && leagues && leagues.length === selectedLeagues.length) {
      setAllLeaguesSelected(true);
    }
  },[mixSelectedLeagues, bodySelectedLeagues, leagues])

  const handleOKClick = () => {
    const selectedLeagues = mixBody === 'maung' ? mixSelectedLeagues : bodySelectedLeagues;
    handleSongClick();
    const selection = allLeaguesSelected
      ? leagues.map((league) => league.leagueName)
      : selectedLeagues;
    onLeagueSelection(selection);
    setSelectedLeagueData(selection);
    onClose();
  };

  const handleAllSelectedChange = () => {
    setSelectedLeagueData([]);
  };



  return (
    <div className="py-1 text-bg_header overflow-auto h-screen dark:bg-bg_header_dark transform transition-transform shadow-lg">
      <div className={`flex px-2 fixed top-0 w-full h-[90px] justify-between ${isWideWindow ? 'max-w-7xl' : ''} items-center text-center dark:text-white`}>
        <div className="flex justify-center items-center">
          {/* <span className="dark:hidden pr-3">
            <img src={league} alt="league-logo"></img>
          </span>
          <span className="hidden dark:block px-2">
            <img src={tune} alt="Tune"></img>
          </span> */}
          <MdOutlineTune size={26} className='mx-2' />
          <h2 className="text-lg">
            {leagues.length} League / {events} Events
          </h2>
        </div>
        <div className="flex items-center px-3 py-1" onClick={handleOKClick}>
          {/* <img src={okIcon} alt="Ok"></img> */}
          <BsFillCheckCircleFill size={20} />
          <label
            htmlFor="checked-checkbox"
            className="ml-2 text-md font-medium text-black dark:text-white"
          >
            OK
          </label>
        </div>
      </div>
      <div className="container px-5 mt-20 h-12 flex items-center">
        <input
          id="filter-checkbox"
          type="checkbox"
          checked={allLeaguesSelected}
          value=""
          className="w-4 h-4 text-neutral-700 bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-gray-800 focus:ring-2 d dark:border-gray-600 focus:outline-none"
          onClick={() => setAllLeaguesSelected(!allLeaguesSelected)}
          onChange={handleAllSelectedChange}
        />
        <label
          htmlFor="filter-checkbox"
          className="ml-2 text-sm font-medium  text-gray-900 dark:text-gray-300"
        >
          {allLeaguesSelected ? 'Deselect All' : 'Select All'}
        </label>
      </div>
      <ul className='mt-2 mb-2 overflow-y-scroll h-[80vh]'>
        {leagues &&
          leagues.map((league, index) => (
            <li
              className={`${index % 2 === 0 ? 'bg-bg_filter dark:bg-bg_bet/[0.05]' : ''
                } z-40 w-full flex items-center px-10 h-[52px]`}
              key={index}
            >
              <div>
                <input
                  id={`filter-checkbox-${league.leagueId}`}
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-neutral-700 bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-100 dark:border-gray-600 focus:outline-none"
                  onChange={() => handleCheckboxChange(league.leagueName)}
                  checked={
                    allLeaguesSelected ||
                    ((mixBody === 'maung' && mixSelectedLeagues.length > 0 && mixSelectedLeagues.includes(league.leagueName)) || 
                    (mixBody !== 'maung' && bodySelectedLeagues.length > 0 && bodySelectedLeagues.includes(league.leagueName)))
                  }
                />
                <label
                  htmlFor={`filter-checkbox-${league.leagueId}`}
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  {league.leagueName}
                </label>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default FilterView;
