import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import sideBarReducer from '../features/common/components/sidebar/sidebar.actions';
import commonReducer from '../features/common/actions/common.actions';
import loginReducer from '../features/pages/login/login.actions';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    sidebar: sideBarReducer,
    common: commonReducer,
    login: loginReducer,
  },
});
